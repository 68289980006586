(function () {

	'use strict';

	angular.module('app').config(['$stateProvider', function ($stateProvider) {

		$stateProvider.state('layout.about-sl', {
			url: "/o-nas",
			//controller: 'LoginController as vm',
			templateUrl: 'App_Js/app/about/about-sl.html',
			data: {
				pageTitle: 'O nas',
				layoutClass: 'about'
			}
		});

		$stateProvider.state('layout.about-en', {
			url: "/about",
			//controller: 'LoginController as vm',
			templateUrl: 'App_Js/app/about/about-en.html',
			data: {
				pageTitle: 'About us',
				layoutClass: 'about'
			}
		});

	}]);
})();