(function () {

	'use strict';

	angular.module('app').config(['$stateProvider', 'EDITOR_MODES', 'USER_ROLES', function ($stateProvider, EDITOR_MODES, USER_ROLES) {

		$stateProvider.state('layout.profile.poieditor', {
			url: "/profile/editor/pois",
			templateUrl: "App_Js/app/profile/poi-editor/poi-editor.html",
			controller: 'PoiEditorCtrl as vm',
			data: {
				pageTitle: 'Urejanje POI'
			}
		});

	}]);
})();