(function () {

	'use strict';

	// module creation
	var services = angular.module('app.services', ['ngResource']);

	// service base
	angular.module('app.services').factory('serviceBase', serviceBase);

	serviceBase.$inject = ['$log', '$location'];

	function serviceBase($log, $location) {

		var logger = $log.getInstance('serviceBase'),

		applicationName = 'geago',

		service = {
			geagoApi: geagoApi,
			geagoApiUrlv1: geagoApiUrlv1,
			geagoApiUrlv2: geagoApiUrlv2,
			getLeftPart: getLeftPart,
			elevationServiceUrl: elevationServiceUrl
		};

		return service;


		/////////


		function getLeftPart() {

			if ($location.host() === 'localhost')
				return '//' + $location.host() + ':' + $location.port() + '/' + applicationName;

			return '//' + $location.host() + ':' + $location.port();
		}

		function geagoApi() {
			/*jshint validthis: true */
			return this.getLeftPart() + '/api';
		}

		function geagoApiUrlv1() {
			/*jshint validthis: true */
			return this.getLeftPart() + '/api/v1';
		}

		function geagoApiUrlv2() {
			/*jshint validthis: true */
			return this.getLeftPart() + '/api/v2';
		}

		function elevationServiceUrl() {
			return '//' + $location.host() + ':' + $location.port() + '/nukleus71/api/elevation/v71/Luizem@Luz';
		}
	}
})();