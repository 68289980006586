(function () {

	'use strict';

	angular.module('app.config', []).provider('config', config);

	function config() {

		var options = {};

		this.config = function (opt) {
			angular.extend(options, opt);
		};

		this.$get = [function () {
			if (!options) {
				throw new Error('Missing configuration options.');
			}
			return options;
		}];
	}
})();