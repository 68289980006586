(function () {

	'use strict';

	angular.module('app').controller('ImageUploadController', ImageUploadController);

	ImageUploadController.$inject = ['$rootScope', '$scope', '$stateParams', '$state', '$log', '$q',
		'localStorageService', 'serviceBase', 'alertService', 'authService', 'userProfileService', 'flowFactory'];

	function ImageUploadController($rootScope, $scope, $stateParams, $state, $log, $q,
		localStorageService, serviceBase, alertService, authService, profileService, flowFactory) {

		var logger = $log.getInstance($scope.controllerName);
		var uploadApi = serviceBase.geagoApiUrlv2() + '/uploads/images/flow';

		var vm = this;

		// ng-flow uploader instance
		vm.flowInstance = flowFactory.create({
			target: uploadApi,
			testChunks: true,	// tests if chunk already exists on server
			headers: {
				"Authorization": authService.getActiveSessionAuthHeader().Authorization
			}
		});

		vm.fileAdded = fileAdded;
		vm.fileError = fileError;
		vm.fileSuccess = fileSuccess;

		init();

		/////////

		function init() {
		}

		function fileSuccess(file, msg, flow) {
		}

		function fileAdded(file, event, flow) {
			//flow.opts.query = { someParam: 'a', otherParam: 'b' };
		}

		function fileError(file, message, flow) {
			//file = file;
		}
	}
})();