(function () {

	'use strict';

	angular.module('app').config(['$stateProvider', function ($stateProvider) {

		$stateProvider.state('layout.public.stories', {
			url: "/stories",
			reloadOnSearch: false,
			data: {
				pageTitle: 'Zgodbe',
				pageUrl: '//www.geago.eu/stories',
				metaDescription: 'Zgodbe in opisi Slovenije kot jih pišejo naši uporabniki',
				layoutClass: 'browse story'
			},
			views: {
				'content@layout.public': {
					templateUrl: 'App_Js/app/stories/storiesBrowse.html',
					controller: 'StoriesBrowseController as vm',
				}
			}
		});

		$stateProvider.state('layout.public.stories-by-tag', {
			url: "/stories/tag/:tag",
			reloadOnSearch: false,
			data: {
				pageTitle: 'Zgodbe',
				pageUrl: '//www.geago.eu/stories/tag',
				metaDescription: 'Zgodbe in opisi Slovenije kot jih pišejo naši uporabniki',
				layoutClass: 'browse story'
			},
			views: {
				'content@layout.public': {
					templateUrl: 'App_Js/app/stories/storiesBrowse.html',
					controller: 'StoriesBrowseController as vm',
				}
			}
		});


		$stateProvider.state('layout.public.stories-by-author', {
			url: "/stories/author/:author",
			reloadOnSearch: false,
			data: {
				pageTitle: 'Zgodbe',
				layoutClass: 'browse story'
			},
			views: {
				'content@layout.public': {
					templateUrl: 'App_Js/app/stories/storiesBrowse.html',
					controller: 'StoriesBrowseController as vm',
				}
			}
		});


		$stateProvider.state('layout.public.stories.display', {
			url: "/:id:int/:slug",
			data: {
				pageTitle: 'Zgodbe',
				layoutClass: 'display story'
			},
			views: {
				'content@layout.public': {
					templateUrl: 'App_Js/app/stories/storyDisplay.html',
					controller: 'StoryDisplayController as vm',
				}
			}
		});
	}]);
})();