(function () {

	'use strict';

	angular.module('app').controller('TileGridController', TileGridController);

	TileGridController.$inject = ['$rootScope', '$scope', '$attrs', '$log', '$filter', '$location', '$stateParams'];

	function TileGridController($rootScope, $scope, $attrs, $log, $filter, $location, $stateParams) {

		var logger = $log.getInstance($scope.controllerName);

		var vm = this;
		vm.paging = true;	// default

		init();

		function init() {

			if (vm.paging && !vm.pageSize)
				vm.pageSize = 12;

		}

	}
})();