angular.module('app').controller('NavbarController', NavbarController);

NavbarController.$inject = ['$rootScope', '$scope', '$log', '$filter', '$location', '$stateParams', '$translatePartialLoader', '$translate'];

function NavbarController($rootScope, $scope, $log, $filter, $location, $stateParams, $translatePartialLoader, $translate) {

	var vm = this;

	vm.showMoreOptions = false;
	vm.activeLanguage = $translate.use();
	
	vm.toggleMoreOptions = function () {
		vm.showMoreOptions = !vm.showMoreOptions;
	};
}