(function () {

	'use strict';

	angular.module('app').controller('HomeController', HomeController);

	HomeController.$inject = ['$rootScope', '$scope', '$log', '$filter', '$location', '$stateParams', '$state', '$anchorScroll',
		'contentService', 'mapService', 'poisService', 'routesService', 'storiesService', 'CONTENT_TYPES'];

	function HomeController($rootScope, $scope, $log, $filter, $location, $stateParams, $state, $anchorScroll,
		contentService,	mapService, poisService, routesService, storiesService, CONTENT_TYPES) {

		var logger = $log.getInstance($scope.controllerName);

		var vm = this;

		vm.filters = {};
		vm.filters.collapsed = true;

		vm.filters.categories = {};

		vm.filters.active = {};
		vm.filters.activeIds = [];

		// se uporablja za klice API-jev in lahko vključuje tudi parametre, ki niso v querystringu
		vm.query = {};
		vm.query.top = 12;

		// se uporablja samo za prikaz v querystringu (ponavadi ima manj parametrov kot query)
		vm.queryString = {};

		vm.autocomplete = autocomplete;
		vm.onAutocompleteSelected = onAutocompleteSelected;

		// paging
		vm.page = 1;
		vm.pageSize = 12;
		vm.prevPageLink = '';
		vm.nextPageLink = '';

		vm.content = {};
		vm.content.loading = false;
		vm.content.total = 0;
		vm.content.pageStart = 1;
		vm.content.pageEnd = vm.pageSize;

		// for loaded pois to use for exclusion for rendering poi dot markers
		vm.pois = [];

		// paging
		vm.nextPage = function () {

			vm.page++;
			vm.queryString.skip = vm.query.skip = vm.pageSize * (vm.page - 1);
			vm.query.top = vm.pageSize;

			refreshContent(vm.nextPageLink);

			if (vm.page > 1) {

				var pageEnd = vm.pageSize * vm.page;
				if (pageEnd > vm.content.total)
					vm.content.pageEnd = vm.content.total;
				else
					vm.content.pageEnd = pageEnd;

				vm.content.pageStart = (vm.page - 1) * vm.pageSize + 1;
			}
			else {
				vm.content.pageStart = 1;
				vm.content.pageEnd = vm.pageSize;
			}

			$location.hash('top');
			$anchorScroll();
		};

		vm.previousPage = function () {

			if (vm.page > 1)
				vm.page--;

			vm.queryString.skip = vm.query.skip = vm.pageSize * (vm.page - 1);
			vm.query.top = vm.pageSize;

			refreshContent(vm.prevPageLink);

			var pageEnd = vm.pageSize * vm.page;
			if (pageEnd > vm.content.total)
				vm.content.pageEnd = vm.content.total;
			else
				vm.content.pageEnd = pageEnd;

			vm.content.pageStart = (vm.page - 1) * vm.pageSize + 1;

			$location.hash('top');
			$anchorScroll();
		};


		init();


		function init() {

			logger.debug('init');

			parseQuerystring();

			$scope.$on('mapQueryChanged', mapQueryChanged);
			$scope.$on('filtersChanged', filtersChanged);

			if (vm.queryString.lat && vm.queryString.lon) {
				$rootScope.$broadcast('setLocation', vm.queryString);
			}
			else
				$rootScope.$broadcast('resetMap');	// ta podtalno, preko 2-3 eventov proži mapQueryChanged(), konfuzno!!
		}

		function parseQuerystring() {

			// extent
			mapService.parseMapLocationFromQueryString(vm.query, vm.queryString);

			// categories
			var categories = $location.search().categories;
			if (categories)
				vm.query.categories = vm.queryString.categories = categories;

			// paging
			var skip = $location.search().skip;
			if (skip) {
				vm.query.skip = vm.queryString.skip = skip;
				vm.page = (skip / vm.pageSize) + 1;
			}
		}


		function mapQueryChanged(scope, mapQuery) {

			if (mapQuery) {
				mapService.mapQueryToQuery(mapQuery, vm.query, vm.queryString);
				resetPaging();
				refreshContent();
			}
		}

		function getMapQueryFromService() {
			var mapQuery = mapService.mapQuery;
			if (mapQuery)
				mapService.mapQueryToQuery(mapQuery, vm.query, vm.queryString);
		}

		function refreshContent(pagingLink) {

			if (!vm.query.extmaxlat) {
				getMapQueryFromService();
			}

			if (!vm.query.extmaxlat) {
				return;
			}

			vm.content.loading = true;

			// loadaj ročno ali loadaj preko paging linka
			if (pagingLink)
				vm.listRequest = contentService.makeAjaxRequestToUrl(pagingLink).then(function (response) {
					processResponse(response);
				});
			else {
				vm.listRequest = contentService.getContentList('frontpage', 'list-sorted', vm.query).then(
					function (response) {
						processResponse(response);
						loadAndRenderContentIndicators();
					});
			}

		}


		function loadAndRenderContentIndicators() {

			var loadedPoiIds = vm.pois.map(function (poi) {
				return poi.id;
			});

			var indicatorsQuery = {};
			indicatorsQuery.extmaxlat = vm.query.extmaxlat;
			indicatorsQuery.extmaxlon = vm.query.extmaxlon;
			indicatorsQuery.extminlat = vm.query.extminlat;
			indicatorsQuery.extminlon = vm.query.extminlon;
			indicatorsQuery.excludeIds = loadedPoiIds.join();
			indicatorsQuery.top = 200 - loadedPoiIds.length;

			var poiIndicators = [];
			var routeIndicators = [];
			var storyIndicators = [];

			contentService.getContentList('pois', 'indicators', indicatorsQuery).then(function (response) {

				for (var i = 0; i < response.data.results.length; i++) {
					// če nima displayUrl, še ni bil pripravljen VM
					var contentItem = response.data.results[i];

					if (!contentItem.displayUrl && contentItem.contentType) {
						switch (contentItem.contentType.toLowerCase()) {
							case 'PoiIndicators'.toLowerCase():
								poiIndicators.push(contentItem);
								break;
							case 'RouteIndicators'.toLowerCase():
								routeIndicators.push(contentItem);
								break;
							case 'StoryIndicators'.toLowerCase():
								storyIndicators.push(contentItem);
								break;
							default:
								continue;
						}
					}
				}

				mapService.drawPoints(poiIndicators, 'poiIndicators', -1, true, contentService.getIndicatorIcon(), false, 'PoiIndicators');
				//mapService.drawPoints(storyIndicators, 'storyIndicators', undefined, true, contentService.getIndicatorIcon(), false);
				//mapService.drawPoints(routeIndicators, 'routeIndicators', undefined, true, true, false, false, contentService.getIndicatorIcon(), '', false);

			});

		}


		function processResponse(response) {

			// totals
			vm.content.total = response.data.total;

			// paging linki
			vm.prevPageLink = response.data.prevPageLink;
			vm.nextPageLink = response.data.nextPageLink;

			var stories = [];
			var routes = [];
			vm.pois = [];

			// prepare VMs
			for (var i = 0; i < vm.pageSize && i < response.data.results.length; i++) {
				// če nima displayUrl, še ni bil pripravljen VM
				var contentItem = response.data.results[i];

				if (!contentItem.displayUrl && contentItem.contentType) {
					switch (contentItem.contentType.toLowerCase()) {
						case 'pois':
							poisService.prepareItemViewModel(contentItem, false, i + 1);
							vm.pois.push(contentItem);
							break;
						case 'routes':
							routesService.prepareItemViewModel(contentItem, false, i + 1);
							routes.push(contentItem);
							break;
						case 'stories':
							storiesService.prepareItemViewModel(contentItem, false, i + 1);
							stories.push(contentItem);
							break;
						default:
							continue;
					}
				}
			}

			vm.content.items = response.data.results;

			mapService.drawPoints(vm.pois, CONTENT_TYPES.pois, undefined, true, contentService.getMarkerIcon(CONTENT_TYPES.pois), true);
			mapService.drawPoints(stories, CONTENT_TYPES.stories, undefined, true, contentService.getMarkerIcon(CONTENT_TYPES.stories), true);
			mapService.drawLines(routes, CONTENT_TYPES.routes, undefined, true, true, false, false, contentService.getMarkerIcon(CONTENT_TYPES.routes), '', true);

			// update querystring
			$location.search(vm.queryString);

			vm.content.loading = false;
			$rootScope.loadingApp = false;
		}


		function autocomplete(query) {

			var params = {
				top: 10,
				searchquery: query
			};

			return contentService.autocomplete('frontpage', params)
				.then(function (response) {
					return response.data.results;
				});
		}


		function onAutocompleteSelected(item, model, label) {

			if (item.contentType.toLowerCase() === CONTENT_TYPES.areas) {
				$rootScope.$broadcast('areaSelected', item);
			}

			if (item.contentType.toLowerCase() === CONTENT_TYPES.pois) {
				$state.go('layout.public.pois.display', { id: item.id, slug: item.slug }, {});
			}

			if (item.contentType.toLowerCase() === CONTENT_TYPES.routes) {
				$state.go('layout.public.routes.display', { id: item.id, slug: item.slug }, {});
			}
		}

		function filtersChanged(event, filters) {

			if (event === filters)
				return;

			var activeCategories = [];

			// še ni filtrov
			if (!filters.categories.length)
				return;

			var level1 = filters.categories.filter(isActive);

			// add all level 1 active
			activeCategories = level1.slice();

			for (var i = 0; i < level1.length; i++) {

				// add all level 2 active
				var level2 = level1[i].categories.filter(isActive);
				activeCategories = activeCategories.concat(level2);

				// add all level 3 active
				for (var j = 0; j < level2.length; j++) {

					var level3 = level2[j].categories.filter(isActive);
					activeCategories = activeCategories.concat(level3);
				}
			}

			var activeIds = [];

			for (var k = 0; k < activeCategories.length; k++) {
				if (activeCategories[k].active)
					activeIds.push(activeCategories[k].id);
			}

			if (activeIds.length) {
				vm.query.categories = vm.queryString.categories = activeIds.join();
			}
			else {
				vm.query.categories = vm.queryString.categories = null;
			}

			resetPaging();
			refreshContent();
		}

		function isActive(category) {
			if (category.active)
				return true;
			return false;
		}

		function resetPaging() {

			// reset skip
			vm.queryString.skip = vm.query.skip = 0;
			vm.page = 1;
			vm.query.top = vm.pageSize;
		}

	}
})();