(function () {

	'use strict';

	angular.module('app.map').directive('ggMap', function map() {
		var directive = {
			restrict: 'E',
			scope: {},
			bindToController: {
				layers: '='
			},
			templateUrl: 'App_Js/app/ngmap/map.html',
			replace: true,
			controllerAs: 'vm',
			controller: 'GeaGoMapController',
			link: function (scope, element, attrs) {
				scope.vm.mapElement = element;

				if (attrs.visibleRatio)
					scope.vm.visibleRatio = parseFloat(attrs.visibleRatio);

				scope.vm.init();
			}
		};
		return directive;
	});
})();