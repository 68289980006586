(function () {

	'use strict';

	angular.module('app.mediaProvider', []).provider('media', function mediaProvider() {

		var tests = angular.extend({}, window.Modernizr);

		var mediaSize = '';

		return ({
			$get: instantiate,
			addTest: addTest,
			rewriteUrl: rewriteUrl,
			setMediaSize: setMediaSize,
			getMediaSize: getMediaSize,
			selectPartial: selectPartial,
			userAgentAtLeastIE11: userAgentAtLeastIE11
		});

		function instantiate() {

		}

		function addTest(name, test) {
			tests[name] = test;
		}

		function rewriteUrl(url) {
			return url;
		}

		function setMediaSize(size) {
			mediaSize = size;
		}

		function getMediaSize() {
			return mediaSize;
		}

		function selectPartial(partial) {
			return partial + '.' + mediaSize + '.html';
		}

		function userAgentAtLeastIE11(){
			return !!(navigator.userAgent.match(/Trident/) && !navigator.userAgent.match(/MSIE/));
		}

	});


	// media provider config
	angular.module('app.mediaProvider').config(['mediaProvider', function (mediaProvider) {

		// media tests (http://adactio.com/journal/5429/)
		var size = window.getComputedStyle(document.body, ':after').getPropertyValue('content'); // ta property na <body>-ju pride iz CSS media queryev
		mediaProvider.setMediaSize(size.replace(/['"]+/g, ''));

	}]);

})();