(function () {

	'use strict';

	angular.module('app.services').factory('authService', authService);

	authService.$inject = ['$http', '$resource', '$log', '$q', 'serviceBase', 'userSession', 'localStorageService'];

	function authService($http, $resource, $log, $q, serviceBase, userSession, localStorage) {

		var currentUserProfile,

		logger = $log.getInstance('authService'),

		tokenEndpoint = serviceBase.geagoApi() + '/auth/token',

		userProfilesApi = serviceBase.geagoApiUrlv2() + '/users/profile',

		service = {
			tokenEndpoint: tokenEndpoint,
			login: login,
			logout: logout,
			isAuthenticated: isAuthenticated,
			isAuthorized: isAuthorized,
			getAuthenticatedUserProfile: getAuthenticatedUserProfile,
			hasSavedAuthenticatedSession: hasSavedAuthenticatedSession,
			restoreSavedAuthenticatedSession: restoreSavedAuthenticatedSession,
			getActiveSessionToken: getActiveSessionToken,
			getActiveSessionAuthHeader: getActiveSessionAuthHeader
		};

		return service;


		/////////


		function login(credentials) {

			// JSON ne dela z OAuth
			//var formatCredentials = {
			//	grant_type: 'password',
			//	username: credentials.userName,
			//	password: credentials.password
			//};

			// OAuth format
			var formatCredentials = 'grant_type=password' +
				'&username=' + encodeURIComponent(credentials.userName) +
				'&password=' + credentials.password;

			var options = {
				headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' }
			};

			return $http
				.post(tokenEndpoint, formatCredentials, options)
				.then(authSuccess)
				.catch(authFail);

			function authSuccess(response) {

				var token = response.data;

				userSession.create(token.access_token, token.token_type,
					token['.issued'], token['.expires'], token.userName);

				if (credentials.rememberMe || hasSavedAuthenticatedSession()) {
					userSession.save(token);
				}

				return token;
			}

			function authFail(error) {
				$log.error('XHR Auth failed: ' + error.data);
				return $q.reject(error);
			}
		}


		function logout() {

			currentUserProfile = null;
			userSession.destroy();
			userSession.clearSaved();
		}


		function getAuthenticatedUserProfile() {

			// cached, zavij currentUserProfile v promise, ker tudi loadUserProfile() vrača promise
			if (currentUserProfile) {
				var def = $q.defer();
				def.resolve(currentUserProfile);
				return def.promise;
			}

			// hit api
			return loadUserProfile().then(function (response) {
				currentUserProfile = response;
				return currentUserProfile;
			});
		}


		function loadUserProfile() {

			var options = {
				headers: { 'Authorization': userSession.tokenType + " " + userSession.token }
			};

			return $http
				.get(userProfilesApi, options)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(error) {
				$log.error('XHR loadUserProfile failed: ' + error.data);
				return $q.reject(error);
			}

		}


		function isAuthenticated() {
			return !!userSession.token;
		}


		function isAuthorized(authorizedRoles) {
			if (!angular.isArray(authorizedRoles)) {
				authorizedRoles = [authorizedRoles];
			}
			return (authService.isAuthenticated() &&
			  authorizedRoles.indexOf(userSession.userRole) !== -1);
		}


		function hasSavedAuthenticatedSession() {
			// poskusi najti token v localstorage-u
			var token = localStorage.get('token');
			return !!token;
		}


		function restoreSavedAuthenticatedSession() {

			var token = localStorage.get('token');
			if (token) {

				// restavriraj shranjeno sejo
				userSession.create(token.access_token, token.token_type,
					token['.issued'], token['.expires'], token.userName);
			}
		}


		function getActiveSessionToken() {
			return userSession.token;
		}


		function getActiveSessionAuthHeader() {
			return userSession.authorizationHeader;
		}

	}
})();