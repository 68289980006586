(function () {

	'use strict';

	angular.module('app.services').factory('filesService', filesService);

	filesService.$inject = ['$rootScope', '$http', 'serviceBase', 'authService'];

	function filesService($rootScope, $http, serviceBase, authService) {

		var files = [];

		var api = serviceBase.geagoApiUrlv2() + '/uploads/images';

		var _defaultUploaderSettings = {
			maxFileSize: 5000000,
			acceptFileTypes: '/(\\.|\/)(gif|jpg|png)$/i',
			url: api,
			headers: {
				"Authorization": authService.getActiveSessionAuthHeader().Authorization
			},
		};

		var _uploaderSettings = _defaultUploaderSettings;

		return {

			options: function () {
				return _uploaderSettings;
			},

			add: function (file) {
				files.push(file);
			},
			clear: function () {
				files = [];
			},

			updateFiles: function (data) {

				// data.result vsebuje response za zadnji prenešen fajl
				var file = data.result.results[0];

				if (file) {
					files.push(file);
				}
			},

			deleteFile: function (file) {

				//$rootScope.loading++;

				$http.delete(file.deleteUrl)
					.then(function () {
						//state = 'resolved';
						$rootScope.$broadcast('filesService_fileDeleted', file);
					},
						function () {
							//state = 'rejected';
						}
					)
					.finally(function () {
						//$rootScope.loading--;
					});
			},

			clearFile: function () { },

			uploadsFinished: function () {
				if (files && files.length !== 0)
					$rootScope.$broadcast('filesService_uploadsFinished');
			},

			clearFiles: function () {
				files.splice(0);
			},

			getFiles: function () {
				return files;
			}
		};
	}
})();