(function () {

    'use strict';

    angular.module('app').controller('ProfileLayoutController', ProfileLayoutController);

    ProfileLayoutController.$inject = ['$rootScope', '$scope', '$stateParams', '$state', '$log', '$q', '$translatePartialLoader',
		'localStorageService', 'alertService', 'authService', 'userProfileService'];

    function ProfileLayoutController($rootScope, $scope, $stateParams, $state, $log, $q, $translatePartialLoader,
		localStorageService, alertService, authService, profileService) {

        $translatePartialLoader.addPart('profile');

        var logger = $log.getInstance($scope.controllerName);

        var vm = this;

        init();

        /////////

        function init() {

            // preusmeri na login, če ni prijavljen
            if (!authService.isAuthenticated())
                $state.go('layout.login');

            $rootScope.profileContentLoading = 0;

            $scope.ckEditorOptions = {
                language: 'sl',
                allowedContent: true,
                entities: false,
                height: '400px'
            };

            $scope.onCkEditorReady = function () {

            };

            $scope.appendImageToCKEditor = function (file) {
                var editor = CKEDITOR.instances.mainCKEditor;
                editor.insertHtml('<div class="text-image story-image"><img src="' + file.url + '" alt="' + file.name + '" style="width: 100%;"/></div>');
            };

            $scope.appendLinkToCKEditor = function (href) {

                var editor = CKEDITOR.instances.mainCKEditor;
                var selected_text = editor.getSelection().getSelectedText(); // Get Text
                var newElement = new CKEDITOR.dom.element('a');              // Make Paragraff
                newElement.setAttributes({ href: href });                 // Set Attributes
                newElement.setText(selected_text);                           // Set text to element
                editor.insertElement(newElement);                            // Add Element
            };
        }
    }
})();