(function () {

	'use strict';

	// declare top-level module which depends on filters,and services
	var app = angular.module('app',
	[
		'ngSanitize'
		, 'ngCookies'
		, 'logEnhancer'
		, 'ui.bootstrap'
		, 'ui.router'
		, 'ckeditor'
		, 'LocalStorageModule'
		, 'blueimp.fileupload'
		, 'ngMap'
		, 'oc.lazyLoad'
		, 'pascalprecht.translate'
        , 'flow'
		, 'app.config'
		, 'app.mediaProvider'
		, 'app.map'
		, 'app.services'
		, 'app.services.alerts'
		, 'app.services.utils'
	]);

	app.config(['$compileProvider', '$urlRouterProvider', '$stateProvider', '$locationProvider', '$provide', '$logProvider',
		'$urlMatcherFactoryProvider', '$translateProvider', '$translatePartialLoaderProvider',
		'localStorageServiceProvider', 'EDITOR_MODES', 'AUTH_EVENTS', 'USER_ROLES', 'configProvider',
		function ($compileProvider, $urlRouterProvider, $stateProvider, $locationProvider, $provide, $logProvider,
			$urlMatcherFactoryProvider, $translateProvider, $translatePartialLoaderProvider,
			localStorageServiceProvider, EDITOR_MODES, AUTH_EVENTS, USER_ROLES, configProvider) {

			// debug mode?
			var debugMode = false;

			// debug settings
			$compileProvider.debugInfoEnabled(debugMode);
			$logProvider.debugEnabled(debugMode);

			// localStorage prefix
			localStorageServiceProvider.setPrefix('geago');

			// controller name provider
			$provide.decorator('$controller', ['$delegate',
				function ($delegate) {
					return function (constructor, locals, later, indent) {
						if (typeof constructor === "string") {
							locals.$scope.controllerName = constructor;
						}
						return $delegate(constructor, locals, later, indent);
					};
				}]);

			// html5mode, hashbangs fallback
			$locationProvider.html5Mode(true);
			$locationProvider.hashPrefix("!");

			// configure translate provider
			$translateProvider
				.useLoader('$translatePartialLoader', {
					urlTemplate: 'App_Js/app/{part}/i18n/{lang}.json'	// part = module directory name
				})
				.registerAvailableLanguageKeys(['en', 'sl'], {
					'en*': 'en',
					'sl*': 'sl'
				})
				.determinePreferredLanguage()		// try to find out preferred language by yourself
				.fallbackLanguage(['en'])
				.useLocalStorage();					// uses cookie storage as fallback

			// For any unmatched url, redirect to /sl/
			//$urlRouterProvider.otherwise("/en/");		// TODO: temp!

			//$urlRouterProvider.otherwise(function ($injector, $location) {
			//	var $state = $injector.get('$state');
			//	var $stateParams = $injector.get('$stateParams');
			//	$state.go('layout.public.home');
			//});

			// How can I have my routes be activated when the url contains either a trailing slash or not?
			$urlMatcherFactoryProvider.strictMode(false);

			// app config load
			//$.get('/config/config.json', function (configData) {

			//	configProvider.config(configData);

			//});

			// must configure!
			$translateProvider.useSanitizeValueStrategy('escape');

			// preload some translations
			$translatePartialLoaderProvider.addPart('navbar');
			$translatePartialLoaderProvider.addPart('home');
			$translatePartialLoaderProvider.addPart('paging');
			$translatePartialLoaderProvider.addPart('tiles');
			$translatePartialLoaderProvider.addPart('ngmap');
			$translatePartialLoaderProvider.addPart('pinpoint');
			$translatePartialLoaderProvider.addPart('pois');
			$translatePartialLoaderProvider.addPart('routes');
			$translatePartialLoaderProvider.addPart('stories');
			$translatePartialLoaderProvider.addPart('filters');
			$translatePartialLoaderProvider.addPart('footer');

		}]);

	app.run(['$rootScope', '$state', '$stateParams', '$log', '$location', '$window', '$translate',
		function ($rootScope, $state, $stateParams, $log, $location, $window, $translate) {

			// listen for translatePartialLoaderStructureChanged which happens on $translatePartialProvider.addPart()
			$rootScope.$on('$translatePartialLoaderStructureChanged', function () {
				$translate.refresh();
			});

			// if url contains lang code, override the angular-translate detected code
			var currentUrl = $location.path();

			var lang;

			// (SL) if url language not empty, enforce url language
			if (currentUrl.toLowerCase().startsWith('sl') || currentUrl.toLowerCase().startsWith('/sl')) {
				lang = 'sl';
			}

			// (EN) if url language not empty, enforce url language
			else if (currentUrl.toLowerCase().startsWith('en') || currentUrl.toLowerCase().startsWith('/en')) {
				lang = 'en';
			}
			else
				lang = $translate.use();	// language from translate service if available

			if (!lang)
			{
				var fallbackLangs = $translate.fallbackLanguage();
				if (fallbackLangs && fallbackLangs.length > 0)
					lang = fallbackLangs[0];
				else
					lang = 'en';
			}

			$translate.use(lang);
			document.documentElement.setAttribute('lang', lang);	// sets "lang" attribute to html tag

			// if at empty app root, redirect to correct language root
			if (!currentUrl || currentUrl === '/') {
				$state.go('layout.public.home', { lang: lang });
			}

			// on successful applying translations by angular-translate (when switching cultures programatically)
			$rootScope.$on('$translateChangeSuccess', function (event, data) {

			});

			var logger = $log.getInstance('app running');

			// It's very handy to add references to $state and $stateParams to the $rootScope
			// so that you can access them from any scope within your applications.For example,
			// <li ng-class="{ active: $state.includes('contacts.list') }"> will set the <li>
			// to active whenever 'contacts.list' or one of its decendents is active.
			$rootScope.$state = $state;
			$rootScope.$stateParams = $stateParams;

			$rootScope.$on('$stateChangeSuccess', function (event) {

				logger.debug('state change success');

				if (!$window.ga)
					return;

				$window.ga('send', 'pageview', { page: $location.path() });
			});

		}]);
})();