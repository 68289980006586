(function () {

	'use strict';

	angular.module('app.services').factory('userProfileService', userProfileService);

	userProfileService.$inject = ['$http', '$log', '$q', 'serviceBase', 'authService'];

	function userProfileService($http, $log, $q, serviceBase, authService) {

		var currentUserProfile,

		logger = $log.getInstance('authService'),

		userProfilesApi = serviceBase.geagoApiUrlv2() + '/users/profile',
		userStoriesApi = serviceBase.geagoApiUrlv2() + '/users/stories',

		service = {
			getProfile: getProfile,
			updateProfile: updateProfile,
			getStories: getStories
		};

		return service;


		/////////

		function getProfile() {
			return authService.getAuthenticatedUserProfile();
		}


		function updateProfile(profile) {
			return $http
				.put(userProfilesApi, profile)
				.then(updateCompleted)
				.catch(updateFailed);

			function updateCompleted(response) {
				return response.data;
			}

			function updateFailed(error) {
				$log.error('XHR Failed for update: ' + error.data);
				return $q.reject(error);
			}
		}

		function getStories(params) {

			if (!params)
				params = { top: 10 };

			return $http
				.get(userStoriesApi, { params: params })
				.then(getItemsCompleted)
				.catch(getItemsFailed)
				.finally(getItemsFinally);


			function getItemsCompleted(response) {
				return response;
			}

			function getItemsFailed(error) {
				$log.error('XHR Failed for getStories: ' + error.data);
				return $q.reject(error);
			}

			function getItemsFinally() {
			}
		}

	}
})();