(function () {

	'use strict';

	angular.module('app.services').constant('CONTENT_TYPES', {
		pois: 'pois',
		routes: 'routes',
		stories: 'stories',
		events: 'events',
		areas: 'areas',
		indicators: 'indicators'
	});

})();