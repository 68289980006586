(function () {

	'use strict';

	angular.module('app.map').factory('mapService', mapService);

	mapService.$inject = ['$rootScope', '$location', 'MapLayer'];

	function mapService($rootScope, $location, MapLayer) {

		var layers = [];
		var mapQueries = {};


		return {

			layers: layers,


			mapQueries: mapQueries,


			addLayer: function (layer) {
				layers.push(layer);
			},


			clearLayers: function () {
				layers.splice(0, layers.length);
			},


			ensureLayer: function (layerId, layerType, layerOrder, layerContentType) {

				if (!layerContentType)
					layerContentType = 'Pois';

				var layer = this.getLayer(layerId);
				if (!layer) {
					layer = new MapLayer(layerId, layerType, layerOrder, layerContentType);
					this.addLayer(layer);
				}
				return layer;
			},


			getLayer: function (layerId) {
				var result = null;
				if (layers.length) {
					for (var i = 0; i < layers.length; i++) {
						if (layers[i].id.toLowerCase() === layerId) {
							result = layers[i];
							break;
						}

					}
				}
				return result;

			},


			prepareMapLayer: function (items, layerName, layerType, icon, numberedIcons, layerOrder, layerContentType) {

				if (!items)
					throw new MapLayerException("items parameter is required for prepareMapLayer()");

				if (!layerName)
					throw new MapLayerException("layerName parameter is required for prepareMapLayer()");

				if (!layerType)
					throw new MapLayerException("layerType parameter is required for prepareMapLayer()");

				if (layerOrder === undefined)
					layerOrder = this.layers.length + 1;

				var layer = this.ensureLayer(layerName, layerType, layerOrder, layerContentType);
				layer.defaultIcon = icon;

				layer.clear();

				for (var i = 0; i < items.length; i++) {
					if (!items[i].order)
						items[i].order = i + 1;
				}

				layer.setFeatures(items, numberedIcons);
				return layer;
			},


			drawPoints: function(items, layerName, layerOrder, clearLayer, markerIcon, numberedMarkerIcons, layerContentType){

				if (!items)
					throw new MapLayerException("items parameter is required for drawPoints()");

				if (!layerName)
					throw new MapLayerException("layerName parameter is required for drawPoints()");

				if (layerOrder === undefined)
					layerOrder = this.layers.length + 1;

				var layer = this.ensureLayer(layerName, 'point', layerOrder, layerContentType);
				layer.defaultIcon = markerIcon;

				if (clearLayer)
					layer.clear();

				layer.setFeatures(items, numberedMarkerIcons);

				return layer;
			},


			drawLines: function (items, layerName, layerOrder, clearLayer, addLineStartPoints,
				addLineDirection, addLineEndpoints, startPointMarkerIcon, endPointMarkerIcon, numberedMarkerIcons) {

				if (!items)
					throw new MapLayerException("items parameter is required for drawLines()");

				if (!layerName)
					throw new MapLayerException("layerName parameter is required for drawLines()");

				if (layerOrder === undefined)
					layerOrder = this.layers.length + 1;

				var layer = this.ensureLayer(layerName, 'polyline', layerOrder);

				if (clearLayer)
					layer.clear();

				layer.setFeatures(items, numberedMarkerIcons, addLineDirection);

				// start points layer
				if (addLineStartPoints) {
					var startPointLayerName = layerName + '_start_points';
					var startPointLayer = this.ensureLayer(startPointLayerName, 'point', layerOrder + 1);
					var startPoints = [];

					for (var i = 0; i < items.length; i++) {
						var item = items[i];
						var startPoint = jQuery.extend(true, {}, item);
						startPointLayer.extractStartPointForLineStringFeature(startPoint);
						startPoints.push(startPoint);
					}

					this.drawPoints(startPoints, startPointLayerName, layerOrder + 1, clearLayer, startPointMarkerIcon, numberedMarkerIcons);
				}


				// end points layer
				// TODO!


				return layer;

			},


			parseMapLocationFromQueryString: function (query, queryString) {
				var zoom = $location.search().z;
				if (zoom)
					query.zoom = queryString.z = zoom;

				var centerLat = $location.search().lat;
				if (centerLat)
					queryString.lat = centerLat;

				var centerLon = $location.search().lon;
				if (centerLon)
					queryString.lon = centerLon;
			},


			mapQueryToQuery: function (mapQuery, query, queryString) {
				if (mapQuery) {
					query.extmaxlon = mapQuery.extmaxlon;
					query.extmaxlat = mapQuery.extmaxlat;
					query.extminlon = mapQuery.extminlon;
					query.extminlat = mapQuery.extminlat;
					query.zoom = mapQuery.zoom;
					if (queryString) {
						queryString.z = mapQuery.zoom;
						queryString.lat = mapQuery.center.lat();
						queryString.lon = mapQuery.center.lng();
					}
				}
			},

			extractStartPointForLineStringFeature: function (layer, feature) {
				return layer.extractStartPointForLineStringFeature(feature);
			}
		};
	}

	function MapLayerException(message) {

		this.message = message;
		this.name = "MapLayerException";
	}


})();
