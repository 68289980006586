(function () {

	'use strict';

	angular.module('app').controller('ElevationChartController', ['$scope', '$rootScope',
		function ($scope, $rootScope) {
			var vm = this;

			init();

			function init() {
				vm.chartCreated = false;
				$scope.$watch('vm.chartCreated', function (newVal, oldVal) {
					if (newVal && (vm.path || vm.elevationData) && !vm.chartDrawn) {
						if (vm.path)
							drawChart();
						else
							plotElevationFromElevationData(vm.elevationData);
						vm.chartDrawn = true;
					}
				}, true);

				$scope.$watch('vm.path', function (newVal, oldVal) {
					if (newVal && vm.chartCreated && !vm.chartDrawn) {
						drawChart();
						vm.chartDrawn = true;
					}
				}, true);


				$scope.$watch('vm.elevationData', function (newVal, oldVal) {
					if (newVal && vm.chartCreated && !vm.chartDrawn) {
						if (vm.elevationData) {
							plotElevationFromElevationData(vm.elevationData);
							vm.chartDrawn = true;
						}
					}
				}, true);

			}

			function drawChart() {

				vm.elevator = new google.maps.ElevationService();
				drawPath();
			}


			function drawPath() {

				// Create a new chart in the elevation_chart DIV.
				var chart = vm.chart;
				var path = [];

				if (vm.path.geometry.type.toLowerCase() === 'linestring') {
					//for (var i = 0; i < vm.path.coordinates.length; i++)
					for (var i = 0; (i < 512 && i < vm.path.geometry.coordinates.length) ; i++) {
						var latlng = new google.maps.LatLng(vm.path.geometry.coordinates[i][1], vm.path.geometry.coordinates[i][0]);
						path.push(latlng);
					}
				}


				var pathRequest = {
					'path': path,
					'samples': 512
				};

				// Initiate the path request.
				vm.elevator.getElevationAlongPath(pathRequest, plotElevation);
			}



			function plotElevationFromElevationData(results) {

				vm.elevationPoints = results.data[0];

				// Extract the elevation samples from the returned results
				// and store them in an array of LatLngs.
				var elevationPath = [];

				vm.data = new google.visualization.DataTable();
				var data = vm.data;
				data.addColumn('number', 'distance');
				data.addColumn('number', 'elevation');
				data.addColumn({ 'type': 'string', 'role': 'tooltip', 'p': { 'html': true } });

				var distance = vm.elevationPoints[vm.elevationPoints.length - 1][3];

				var distDisplay = '';

				var hAxisFormat = '';

				for (var i = 0; i < vm.elevationPoints.length; i++) {

					var elevationPointData = vm.elevationPoints[i];
					var location = new google.maps.LatLng(elevationPointData[1], elevationPointData[0]);
					var elevation = elevationPointData[2];
					elevationPointData.location = location;
					elevationPath.push(location);
					elevationPointData.elevation = Math.round(elevation);

					if (!vm.minElevation || vm.minElevation > elevationPointData.elevation)
						vm.minElevation = elevationPointData.elevation;
					if (!vm.maxElevation || vm.maxElevation < elevationPointData.elevation)
						vm.maxElevation = elevationPointData.elevation;

					var dist = elevationPointData[3];
					if (dist !== null) {
						if (dist > 10000) {
							distDisplay = (dist / 1000).toFixed(2) + ' km';
							dist = Number(dist.toFixed(0)) / 1000;
							hAxisFormat = '#';
						}
						else {
							distDisplay = (dist / 1000).toFixed(1) + ' km';
							dist = dist / 1000;
							hAxisFormat = '#.#';
						}

						elevationPointData[3] = dist;

						distDisplay = distDisplay.replace('.', ',');	// TEMP! TODO!

						elevation = elevationPointData.elevation;
						data.addRow([dist, elevation, '<div class="chartTooltip"><div>Razdalja: ' + distDisplay + ' </div><div>Nadm. višina: ' + elevation.toFixed(0).toString() + ' m</div></div>']);

					}
				}

				var diff = vm.maxElevation - vm.minElevation;
				if (diff < 100)
					diff = 100;
				else {
					if (vm.minElevation < 20) {
						vm.maxElevation += 100;
					}
					else
						vm.maxElevation += 80;
					vm.minElevation -= 20;
				}


				vm.options = {
					hAxis: { format: hAxisFormat, title: 'razdalja (km)', titleTextStyle: { color: '#333' } },
					vAxis: { format: '', title: 'nv (m)', minValue: vm.minElevation, maxValue: vm.minElevation + diff },
					legend: { position: 'none' },
					width: '100%',
					height: 200,
					fontSize: 14,
					fontName: 'Roboto',
					tooltip: { isHtml: true, trigger: 'selection' },
					backgroundColor: 'transparent',
					colors: ['#EF4036'],
					selectionMode: 'single',
					enableInteractivity: false


					//axisTitlesPosition: 'in',


				};

				// Draw the chart using the data within its DIV.
				//document.getElementById('elevation_chart').style.display = 'block';
				vm.chart.draw(vm.data, vm.options);

				// google.visualization.events.addListener(vm.chart, 'onmouseover', mouseOverHandler);
				// google.visualization.events.addListener(vm.chart, 'onmouseout', mouseOutHandler);

				vm.domElement[0].onmousemove = function (e, args) {

					var offsetX = e.layerX === undefined ? e.offsetX : e.layerX;
					var xVal = vm.chart.getChartLayoutInterface().getHAxisValue(offsetX);
					var data = getDataAndIndex(xVal);
					if (data) {
						var ind = data.index;
						vm.chart.setSelection([{ row: ind, column: 1 }]);
						$rootScope.$broadcast('elevationPointHovered', data.elevationPoint, vm.minElevation, vm.maxElevation);
					}

				};

				vm.domElement[0].onmouseleave = function (evt, args) {

					vm.chart.setSelection([]);
					$rootScope.$broadcast('elevationPointOut');

				};

				$(window).resize(function () {
					vm.chart.draw(vm.data, vm.options);
				});
			}

			function getDataAndIndex(xVal) {
				for (var i = 0; i < vm.elevationPoints.length; i++) {
					var row = vm.elevationPoints[i];
					var dist = row[3];

					if (dist >= xVal)
						return { index: i, elevationPoint: row };
				}
				return null;

			}





			function plotElevation(results, status) {

				if (status !== google.maps.ElevationStatus.OK) {
					return;
				}

				vm.elevationPoints = results;

				// Extract the elevation samples from the returned results
				// and store them in an array of LatLngs.
				var elevationPath = [];

				vm.data = new google.visualization.DataTable();
				var data = vm.data;
				data.addColumn('number', 'distance');
				data.addColumn('number', 'elevation');
				data.addColumn({ 'type': 'string', 'role': 'tooltip', 'p': { 'html': true } });
				for (var i = 0; i < results.length; i++) {
					elevationPath.push(vm.elevationPoints[i].location);
					vm.elevationPoints[i].elevation = Math.round(vm.elevationPoints[i].elevation);

					if (!vm.minElevation || vm.minElevation > vm.elevationPoints[i].elevation)
						vm.minElevation = vm.elevationPoints[i].elevation;
					if (!vm.maxElevation || vm.maxElevation < vm.elevationPoints[i].elevation)
						vm.maxElevation = vm.elevationPoints[i].elevation;
					var dist = Math.round(google.maps.geometry.spherical.computeLength(elevationPath));
					var elevation = vm.elevationPoints[i].elevation;
					data.addRow([dist, elevation, '<div>Razdalja: ' + dist.toFixed(0) + ' m <br />Nadmorska višina: ' + elevation.toFixed() + ' m</div>']);
				}

				var diff = vm.maxElevation - vm.minElevation;
				if (diff < 100)
					diff = 100;
				else {
					vm.maxElevation += 50;
					vm.minElevation -= 50;
				}



				vm.options = {
					hAxis: { format: '', titleTextStyle: { color: '#333' } },
					vAxis: { format: '', title: 'nv (m)', minValue: vm.minElevation, maxValue: vm.minElevation + diff },
					legend: { position: 'none' },
					width: '100%',
					height: 200,
					fontSize: 14,
					fontName: 'Roboto, Arial, Helvetica Neue, Helvetica, sans-serif',
					tooltip: { isHtml: true },
					backgroundColor: 'transparent',
					//axisTitlesPosition: 'in',


				};

				// Draw the chart using the data within its DIV.
				//document.getElementById('elevation_chart').style.display = 'block';
				vm.chart.draw(vm.data, vm.options);

				google.visualization.events.addListener(vm.chart, 'onmouseover', mouseOverHandler);
				google.visualization.events.addListener(vm.chart, 'onmouseout', mouseOutHandler);

				vm.domElement[0].onmouseover = function (evt, args) {

					var offsetX = evt.offsetX;
					var offsetY = evt.offsetY;

					var cli = vm.chart.getChartLayoutInterface();
					//var xVal = vm.chart.getChartLayoutInterface().getHAxisValue(offsetX);
					//var yVal = vm.chart.getChartLayoutInterface().getVAxisValue(offsetY);

					var loc = cli.getXLocation();
				};



				$(window).resize(function () {
					vm.chart.draw(vm.data, vm.options);
				});
			}

			function mouseOverHandler(data) {
				var elevationPoint = vm.elevationPoints[data.row];
				$rootScope.$broadcast('elevationPointHovered', elevationPoint, vm.minElevation, vm.maxElevation);

			}

			function mouseOutHandler() {
				$rootScope.$broadcast('elevationPointOut');
			}

		}]);
})();