(function () {

	'use strict';

	angular.module('app.services').constant('AUTH_EVENTS', {
		loginSuccess: 'auth-login-success',
		loginFailed: 'auth-login-failed',
		logoutSuccess: 'auth-logout-success',
		sessionTimeout: 'auth-session-timeout',
		notAuthenticated: 'auth-not-authenticated',
		notAuthorized: 'auth-not-authorized'
	});

	angular.module('app.services').constant('USER_ROLES', {
		all: '*',
		admin: 'admin',
		editor: 'editor',
		author: 'author',
		member: 'member'
	});
})();