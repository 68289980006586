(function () {

	'use strict';

	angular.module('app').controller('StoryListController',
		['$rootScope', '$scope', '$stateParams', '$state', 'storiesService', 'userProfileService',
		function ($rootScope, $scope, $stateParams, $state, storiesService, userProfileService) {

			var vm = this;
			vm.stories = {};

			vm.isStoryPublished = function (story) {
				if (story.published)
					return 'Objavljeno';

				return 'Osnutek';
			};

			init();

			/////////

			function init() {

				$rootScope.profileContentLoading++;

				userProfileService.getStories()
					.then(function (response) {
						vm.stories = response.data.results;
					})
				.finally(function () {
					$rootScope.profileContentLoading--;
				});
			}

		}]);
})();