(function () {

	'use strict';

	angular.module('app').directive('ggTileGrid', function tile() {
		var directive = {
			restrict: 'E',
			scope: {
				// =? označuje opcijsko vrednost, pomeni, da pri klicu direktive ni potrebno podati in lahko v ctrlju od direktive podamo default vrednost!
				tiles: '=',		// spremenljivka z vsebino
				spinner: '=',	// spremenljivka na katero je vezan prikaz spinnerja
				classes: '@',	// spremenljivka s CSS classi za dodat
				pageSize: '=?',	// velikost strani
				page: '=?',		// številka strani
				nextPage: '&',	// funkcija za naslednjo stran
				previousPage: '&',	// funkcija za prejšnjo stran
				nextPageLink: '=?',	// link za XHR za nasl. stran
				prevPageLink: '=?'	// link za XHR za prejšnjo stran
			},
			bindToController: true,
			// v 1.4 bo možno tudi takole
			//scope: {},
			//bindToController: {
			//	tiles: '=',
			//	contentType: '@'
			//},
			templateUrl: 'App_Js/app/tiles/tileGrid.html',
			replace: true,
			controllerAs: 'vm',
			controller: 'TileGridController',
			link: function (scope, element, attrs, vm) {

				attrs.$observe('paging', function () {

					vm.paging = scope.$eval(attrs.paging);
				});

			}
		};
		return directive;
	});
})();