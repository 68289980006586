(function () {

	'use strict';

	angular.module('app').controller('ggSlideshowController', ggSlideshowController);

	ggSlideshowController.$inject = ['$rootScope', '$scope', '$log', '$timeout', '$interval', '$translatePartialLoader'];

	function ggSlideshowController($rootScope, $scope, $log, $timeout, $interval, $translatePartialLoader) {

		$translatePartialLoader.addPart('ggSlideshow');

		var logger = $log.getInstance($scope.controllerName);

		var vm = this;
		vm.images = {};
		vm.showThumbs = false;

		vm.currentIndex = 0;
		vm.previousIndex = 0;
		vm.currentSlideTitle = '';

		vm.delay = 5000;


		vm.thumbHoverEnter = function (thumbIndex) {

			vm.currentSlideTitle = vm.images[thumbIndex].title;
			$rootScope.$broadcast('ggSlideshowThumbHoverEntered', thumbIndex);

		};


		vm.thumbHoverLeave = function (thumbIndex) {

			vm.currentSlideTitle = '';
			$rootScope.$broadcast('ggSlideshowThumbHoverLeft', thumbIndex);

		};


		vm.toggleThumbs = function () {

			// slideshow na pause, dokler smo v showThumbs mode-u
			if (!vm.showThumbs) {
				stopTimer();
				vm.currentSlideTitle = '';	// currentSlideTitle na thumbs hover
			}
			// thumbs off, restart slideshow
			else {
				vm.currentSlideTitle = vm.images[vm.currentIndex].title;
				startSlideshow();
			}

			vm.showThumbs = !vm.showThumbs;

		};


		// thumbnail click
		vm.thumbClick = function (thumbIndex) {

			vm.currentIndex = thumbIndex;
			vm.currentSlideTitle = vm.images[vm.currentIndex].title;
			vm.showThumbs = false;
			startSlideshow();

		};


		// specific slide
		vm.goToSlide = function (index) {

			// najprej zamenjam sliko
			if (index >= 0 && index < vm.images.length)
				vm.currentIndex = index;

			// nato skrijem thumbse, če so aktivni
			if (vm.showThumbs)
				vm.showThumbs = false;

			// reset timerja, ker drugač ni ok izkušnja (prehitro preskakovanje)
			resetTimer();
		};


		// next click
		vm.next = function () {
			advance();
			resetTimer();
		};


		// prev click
		vm.prev = function () {
			backtrack();
			resetTimer();
		};


		vm.init = init;


		/////////

		var timer;

		function init() {

			$scope.$watch('vm.currentIndex', function () {
				vm.images.forEach(function (image) {
					image.visible = false;
				});

				vm.images[vm.currentIndex].visible = true;
				vm.currentSlideTitle = vm.images[vm.currentIndex].title;

				$rootScope.$broadcast('ggSlideshowSlideChanged', vm.currentIndex, vm.previousIndex);

			});

			// prijavi se na event od mape
			$scope.$on('photoOnMapClicked', function (event, args) {
				if (args.index >= 0) {
					vm.goToSlide(args.index);
				}
			});

			$scope.$on('$destroy', function () {
				stopTimer();
			});

			startSlideshow();

		}

		/* advance slides */
		function advance() {

			vm.previousIndex = vm.currentIndex;
			if(vm.currentIndex < vm.images.length - 1)
				vm.currentIndex++;
			else
				vm.currentIndex = 0;

		}

		/* backtrack */
		function backtrack() {

			vm.previousIndex = vm.currentIndex;
			if (vm.currentIndex > 0)
				vm.currentIndex--;
			else
				vm.currentIndex = vm.images.length - 1;

		}


		/* reset timer */
		function resetTimer() {

			stopTimer();
			startSlideshow();

		}

		/* stop timer */
		function stopTimer() {

			$interval.cancel(timer);

		}

		/* auto slideshow start */

		function startSlideshow() {

			//timer = $timeout(function () {
			//	vm.start();
			//	timer = $timeout(slideshowFunc, vm.delay);
			//}, vm.delay);

			timer = $interval(advance, vm.delay);
		}

		/* auto slideshow end */
	}
})();