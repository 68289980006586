(function () {

	'use strict';

	angular.module('app').config(['$stateProvider', 'EDITOR_MODES', 'USER_ROLES', function ($stateProvider, EDITOR_MODES, USER_ROLES) {

		$stateProvider.state('layout.profile.categorization', {
			url: "/profile/categorization",
			templateUrl: "App_Js/app/profile/categorization/categorization-editor.html",
			controller: 'CategorizationEditorCtrl as vm',
			data: {
				pageTitle: 'Kategorizacija'
			}
		});

		//$stateProvider.state('layout.profile.stories.edit', {
		//	url: "/edit/:id:int/:slug",
		//	controller: 'StoryEditorController as vm',
		//	data: {
		//		editorMode: EDITOR_MODES.edit,
		//		pageTitle: 'Urejanje zgodbe'
		//	},
		//	views: {
		//		'': {
		//			templateUrl: 'App_Js/app/profile/stories/editor.html',
		//			controller: 'StoryEditorController as vm'
		//		},
		//		'editor-options@layout.profile.stories.edit': {
		//			templateUrl: 'App_Js/app/profile/stories/options/editor-options.html'
		//		}
		//	}
		//});
		
		//$stateProvider.state('layout.profile.stories.new', {
		//	url: "/new",
		//	controller: 'StoryEditorController as vm',
		//	data: {
		//		editorMode: EDITOR_MODES.create,
		//		pageTitle: 'Nova zgodba'
		//	},
		//	views: {
		//		'': {
		//			templateUrl: 'App_Js/app/profile/stories/editor.html',
		//			controller: 'StoryEditorController as vm'
		//		},
		//		'editor-options@layout.profile.stories.new': {
		//			templateUrl: 'App_Js/app/profile/stories/options/editor-options.html'
		//		}
		//	}
		//});

	}]);
})();