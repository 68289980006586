(function () {

	'use strict';

	angular.module('app').directive('ggSlideshow', ['$timeout', function ($timeout) {
		return {
			restrict: 'AE',
			replace: true,
			scope: {
				images: '=',
				delay: '@?'
			},
			bindToController: true,
			controller: 'ggSlideshowController',
			controllerAs: 'vm',
			link: function (scope, element, attrs, ctrl) {

				scope.vm.imageLoaded = function () {
				};

			},
			templateUrl: 'App_Js/app/ggSlideshow/ggslideshow.html'
		};
	}]);

	angular.module('app').directive('ggSlide', function () {
		return {
			require: '^ggSlideshow',
			link: function (scope, element, attrs, slideshowCtrl) {

				if (scope.$last) {
					slideshowCtrl.init();
				}

			}
		};
	});

	// imageloaded event (bind to image load event)
	angular.module('app').directive('imageonload', function () {
		return {
			restrict: 'A',
			link: function (scope, element, attrs, slideshowCtrl) {

				scope.vm.smallestAspect = 0;

				element.bind('load', function () {

					// slideshow settings
					var slidesContainer;
					if (!scope.vm.slideshowWidth) {
						slidesContainer = element.parent().parent('.slideshow-slides');
						scope.vm.slideshowWidth = slidesContainer.outerWidth();
						scope.vm.slideshowHeight = scope.vm.slideshowWidth / (16 / 9);
						scope.vm.slideshowContainerAspect = scope.vm.slideshowWidth / scope.vm.slideshowHeight;
					}

					// set the correct height per aspect
					element.parent().parent('.slideshow-slides').css({
						height: scope.vm.slideshowHeight
					});

					// cannot use $(this).height() because li's are hidden and returns 0, so calculate from original
					//var originalImgWidth = element.outerWidth();
					//var originalImgHeight = element.outerHeight();
					var originalImgWidth = element.get(0).width;
					var originalImgHeight = element.get(0).height;
					var imageAspect = originalImgWidth / originalImgHeight;

					if ((originalImgWidth < scope.vm.slideshowWidth && originalImgHeight < scope.vm.slideshowHeight) ||
						(originalImgWidth < scope.vm.slideshowWidth && originalImgHeight >= scope.vm.slideshowHeight)) {
						$(this).css({
							margin: 'auto auto',
							height: scope.vm.slideshowHeight,
							width: 'auto'
						});

						// slide
						element.parent().css({
							width: '100%',
							textAlign: 'center',
							margin: 'auto'
						});
					}

					else if (originalImgWidth >= scope.vm.slideshowWidth && originalImgHeight < scope.vm.slideshowHeight) {
						$(this).css({
							margin: 'auto auto',
							height: 'auto',
							width: scope.vm.slideshowWidth
						});

						// slide
						element.parent().css({
							width: '100%',
							textAlign: 'center',
							margin: 'auto'
						});
					}

					else {

						if (scope.vm.slideshowContainerAspect < imageAspect) {
							// taller
							$(this).css({
								width: 'auto',
								height: scope.vm.slideshowHeight,
								marginTop: 0,
								marginLeft: -(scope.vm.slideshowHeight * imageAspect - scope.vm.slideshowWidth) / 2
							});
						} else {
							// wider
							$(this).css({
								width: scope.vm.slideshowWidth,
								height: 'auto',
								marginTop: -(scope.vm.slideshowWidth / imageAspect - scope.vm.slideshowHeight) / 2,
								marginLeft: 0
							});
						}
					}

					// call the function that was passed in after single image load
					scope.$apply(attrs.imageonload);
				});

			}
		};
	});
})();