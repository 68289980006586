(function () {

	'use strict';

	angular.module('app').directive('ggElevationChart', function map() {
		var directive = {
			restrict: 'A',
			scope: {
				path: '=',
				elevationData: '='
			},
			bindToController: true,
			// v 1.4 bo možno tudi takole
			//scope: {},
			//bindToController: {
			//	tiles: '=',
			//	contentType: '@'
			//},
			//templateUrl: 'App_Js/app/elevationChart/elevationChart.html',
			replace: false,
			controllerAs: 'vm',
			controller: 'ElevationChartController',
			link: function (scope, element, attrs) {

				scope.vm.domElement = element;
				if (!google.visualization) {
					google.load('visualization', '1', {
						packages: ['corechart'],
						callback: function () {
							scope.vm.chart = new google.visualization.AreaChart(element[0]);
							scope.vm.chartCreated = true;
						}
					});
				}
				else {
					scope.vm.chart = new google.visualization.AreaChart(element[0]);
					scope.vm.chartCreated = true;
				}

			}

		};

		return directive;
	});
})();