(function () {

	'use strict';

	angular.module('app').controller('AppController', ['$rootScope', '$scope', '$stateParams', '$state', '$q', '$timeout', '$log',
		'$document', '$window', '$translate',
		'alertService', 'authService', 'AUTH_EVENTS', 'USER_ROLES', 'mapService',

		function ($rootScope, $scope, $stateParams, $state, $q, $timeout, $log, $document, $window, $translate,
			alertService, authService, AUTH_EVENTS, USER_ROLES, mapService) {

			var vm = this;
			vm.language = $translate.use();

			// app config puts current controller name into $scope.controllerName
			var logger = $log.getInstance($scope.controllerName);	// instance the logger with controller name for context

			// data loading spinner
			$rootScope.loadingContent = 0;
			$rootScope.loadingApp = true;

			$scope.closeAlert = function closeAlert(index) {
				alertService.closeAlert(index);
			};

			$scope.getAlerts = function getAlerts() {
				return alertService.get();
			};

			// user profile
			$scope.userRoles = USER_ROLES;
			$scope.isAuthorized = authService.isAuthorized;

			// setter, ki se uporablja v child kontrolrjih za dostop do currentUser,
			// da se prepreči direktna uporaba $scope.currentUser (shadowing!)
			$scope.setCurrentUser = function (user) {
				$scope.currentUser = user;
			};

			$scope.logout = function () {

				authService.logout();
				$scope.currentUser = null;

				// redirect
				$state.go('login');
			};


			initApp();


			///////


			function initApp() {

				//$rootScope.$on('$viewContentLoading', function (event, viewConfig) {
				//	// Access to all the view config properties.
				//	// and one special property 'targetView'
				//	// viewConfig.targetView
				//	$rootScope.loadingApp = true;
				//});

				logger.debug('init app');

				$scope.$on('$viewContentLoaded', function (event) {

					$rootScope.loadingApp = false;

					logger.debug('view content loaded');

				});

				$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

					logger.debug('state change started');

					mapService.clearLayers();

					logger.debug('mapService cleared');

				});

				// on successful applying translations by angular-translate (when switching cultures programatically)
				$rootScope.$on('$translateChangeSuccess', function (event, data) {

					vm.language = $translate.use();

				});


				initUserProfile();

			}


			function initUserProfile() {
				// samo če še nismo prijavljeni in imamo shranjeno sejo v localstorage, jo povrnemo,
				// sicer pustimo prijavo, ki je aktivna
				if (!authService.isAuthenticated() && authService.hasSavedAuthenticatedSession()) {

					$rootScope.loadingApp = true;

					authService.restoreSavedAuthenticatedSession();

					authService.getAuthenticatedUserProfile()
						.then(success)
						.catch(fail)
						.finally(finalize);
				}

				function success(response) {

					// v AppController-ju
					$scope.currentUser = response;

					//// redirect
					//$state.go('profile.stories'); // TODO! TEMP! 
				}

				// če tu faila, pomeni, da token iz localstorage ni več veljaven
				// TODO: handlaj preusmeritev na ponovno prijavo ali samo obvesti!
				function fail(error) {
					alertService.add('danger', 'Vaša shranjena seja je potekla. Prosimo, ponovno se prijavite.');
					return $q.reject(error);
				}

				function finalize() {
					$rootScope.loadingApp = false;
				}

			}

			//$scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

			//});

			//$scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {

			//});

		}]);
})();