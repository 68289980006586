(function () {

	'use strict';

	angular.module('app').controller('UploaderController', ['$scope', '$stateParams', '$http', 'filesService',
		function ($scope, $stateParams, $http, filesService) {


			var options = filesService.options();
			$scope.options = options;
			$scope.percentage = 0;
			$scope.loadingFiles = true;

			$scope.$on('fileuploadstop', function (e, data) {

				filesService.uploadsFinished();
			});

			$scope.$on('fileuploaddone', function (e, data) {

				filesService.updateFiles(data);
				data.scope.clear(data.files);
			});

		}]).controller('FileDestroyController', [
			'$scope', '$http', 'filesService',
			function ($scope, $http, filesService) {
				var file = $scope.file,
					state;
				if (file.url) {
					file.$state = function () {
						return state;
					};
					file.$destroy = function () {
						filesService.deleteFile(file);
					};
				} else if (!file.$cancel && !file._index) {
					file.$cancel = function () {
						$scope.clear(file);
					};
				}
			}
		]);
})();