(function () {

	'use strict';

	angular.module('app').controller('ProfileController', ProfileController);

	ProfileController.$inject = ['$rootScope', '$scope', '$stateParams', '$state', '$log', '$q',
		'localStorageService', 'alertService', 'authService', 'userProfileService'];

	function ProfileController($rootScope, $scope, $stateParams, $state, $log, $q,
		localStorageService, alertService, authService, profileService) {

		var logger = $log.getInstance($scope.controllerName);

		var vm = this;
		vm.profile = {};

		vm.update = update;

		init();

		/////////

		function init() {

			$rootScope.profileContentLoading++;

			// naloži uporabniški profil
			authService.getAuthenticatedUserProfile()
				.then(function (response) {
					vm.profile = response.results;
				})
				.finally(function () {
					$rootScope.profileContentLoading--;
				});
		}

		function update(profile) {

			$rootScope.profileContentLoading++;

			return profileService.updateProfile(profile)
				.then(updateCompleted)
				.catch(updateFailed)
				.finally(updateFinally);

			function updateCompleted(response) {
				alertService.add('info', 'Vaš profil je shranjen.');
				return response;
			}

			function updateFailed(error) {
				alertService.add('danger', 'Prišlo je do napake pri shranjevanju profila.');
				return $q.reject(error);
			}

			function updateFinally() {
				$rootScope.profileContentLoading--;
			}

		}
	}
})();