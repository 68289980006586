(function () {

	'use strict';

	angular.module('app.services').factory('editorService', editorService);

	editorService.$inject = ['$http', '$log', '$q', '$state', '$translate',
		'serviceBase', 'utilsService', 'contentService'];

	function editorService($http, $log, $q, $state, $translate,
		serviceBase, utilsService, contentService) {

		var logger = $log.getInstance('editorService');

		var api = serviceBase.geagoApiUrlv2();

		var service = {
			loadForEdit: loadForEdit,
			update: update
		};

		return service;

		function loadForEdit(contentType, method, id, params) {

			if (!params)
				params = {};

			if (!params.language)
				params.language = $translate.use();

			var updateGetApi = api + '/' + contentType + '/' + method + '/' + id;

			return $http.get(updateGetApi, { params: params })
				.then(getCompleted)
				.catch(getFailed);

			function getCompleted(response) {
				return response;
			}

			function getFailed(error) {
				$log.error('XHR Failed for getCategory: ' + error.data);
				return $q.reject(error);
			}
		}


		function update(contentType, method, model) {

			var updatePutApi = api + '/' + contentType + '/' + method + '/' + model.id;

			return $http
				.put(updatePutApi, model)
				.then(updateCompleted)
				.catch(updateFailed);

			function updateCompleted(response) {
				return response.data.results;
			}

			function updateFailed(error) {
				$log.error('XHR Failed for update: ' + error.data);
				return $q.reject(error);
			}
		}


	}
})();