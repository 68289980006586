(function () {

	'use strict';

	angular.module('app').config(['$stateProvider', function ($stateProvider) {

		$stateProvider.state('layout.public.routes', {
			url: "/routes",
			reloadOnSearch: false,
			data: {
				pageTitle: 'Izletne poti',
				pageUrl: '//www.geago.eu/routes',
				metaDescription: 'Izbirajte med več kot 300 izletnimi potmi in trasami po Sloveniji',
				layoutClass: 'browse routes'
			},
			views: {
				'content@layout.public': {
					templateUrl: 'App_Js/app/routes/routesBrowse.html',
					controller: 'RoutesBrowseController as vm',
				}
			}
		});

		$stateProvider.state('layout.public.routes.display', {
			url: "/:id:int/:slug",
			data: {
				pageTitle: 'Izletne poti',
				layoutClass: 'display routes'
			},
			views: {
				'content@layout.public': {
					templateUrl: 'App_Js/app/routes/routeDisplay.html',
					controller: 'RouteDisplayController as vm',
				}
			}
		});
	}]);
})();