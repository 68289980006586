(function () {

	'use strict';

	angular.module('app').directive('ggTile', function tile() {
		var directive = {
			restrict: 'E',
			scope: {
				contentItem: '='
			},
			bindToController: true,
			templateUrl: 'App_Js/app/tiles/tile.html',
			replace: true,
			controllerAs: 'vm',
			controller: 'TileController',
			require: ['^ggTileGrid'],
			link: function (scope, element, attrs, ctrls) {

			}
		};
		return directive;
	});
})();