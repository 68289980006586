(function () {

	'use strict';

	angular.module('app').directive('ggFooter', [function () {
		return {
			restrict: 'AE',
			replace: true,
			scope: {
			},
			bindToController: true,
			controller: 'FooterController',
			controllerAs: 'vm',
			link: function (scope, element, attrs, ctrl) {

			},
			templateUrl: 'App_Js/app/footer/footer.html'
		};
	}]);
})();