(function () {

	'use strict';

	angular.module('app.services').factory('categorizationService', categorizationService);

	categorizationService.$inject = ['$http', '$log', '$q', '$state', '$translate',
		'serviceBase', 'utilsService', 'contentService'];

	function categorizationService($http, $log, $q, $state, $translate,
		serviceBase, utilsService, contentService) {

		var logger = $log.getInstance('categorizationService');

		var contentType = 'categorization';

		var api = serviceBase.geagoApiUrlv2() + '/' + contentType;

		var service = {
			getCategory: getCategory,
			getChildren: getChildren,
			getCategorization: getCategorization,
			updateCategorization: updateCategorization
		};

		return service;

		function getCategory(id, params) {

			if (!params)
				params = {};

			if (!params.language)
				params.language = $translate.use();

			return $http.get(api + '/category/' + id, { params: params })
				.then(getCompleted)
				.catch(getFailed);

			function getCompleted(response) {
				return response;
			}

			function getFailed(error) {
				$log.error('XHR Failed for getCategory: ' + error.data);
				return $q.reject(error);
			}
		}

		function getChildren(id, params) {

			if (!params)
				params = {};

			if (!params.language)
				params.language = $translate.use();

			if (id === undefined || id === '')
				id = 'root';

			return $http.get(api + '/category/children/' + id, { params: params })
				.then(getCompleted)
				.catch(getFailed);

			function getCompleted(response) {
				return response;
			}

			function getFailed(error) {
				$log.error('XHR Failed for getChildren: ' + error.data);
				return $q.reject(error);
			}
		}


		function getCategorization(params) {

			if (!params)
				params = {};

			if (!params.language)
				params.language = $translate.use();

			if (!params.random)
				params.random = Math.random();

			return $http.get(api, { params: params })
				.then(getCompleted)
				.catch(getFailed);

			function getCompleted(response) {
				return response;
			}

			function getFailed(error) {
				$log.error('XHR Failed for getCategorization: ' + error.data);
				return $q.reject(error);
			}
		}


		function updateCategorization(model) {
			return $http
				.put(api, model)
				.then(updateCompleted)
				.catch(updateFailed);

			function updateCompleted(response) {
				return response.data.results;
			}

			function updateFailed(error) {
				$log.error('XHR Failed for update: ' + error.data);
				return $q.reject(error);
			}
		}


	}
})();