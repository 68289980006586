(function () {

	'use strict';

	angular.module('app').directive('ggSponsor', function sponsor() {
		var directive = {
			restrict: 'AE',
			scope: {},
			bindToController: {
				sponsor: '='		// spremenljivka s podatki partnerja
			},
			templateUrl: 'App_Js/app/sponsors/sponsor.html',
			replace: true,
			controllerAs: 'vm',
			controller: function () { },
			link: function (scope, element, attrs, vm) {

			}
		};
		return directive;
	});
})();