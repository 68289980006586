(function () {

	'use strict';

	angular.module('app').controller('PoiEditorCtrl',
		['$rootScope', '$scope', '$stateParams', '$state', '$q', 'editorService', 'alertService', 'contentService',
			function ($rootScope, $scope, $stateParams, $state, $q, editorService, alertService, contentService) {

				var vm = this;
				var contentType = 'pois';
				var listMethodName = 'list';

				vm.modelBackup = {};

				// the content we're editing
				vm.editingContent = undefined;

				// searching content
				vm.search = search;

				// cancel editing, revert to model backup
				vm.cancel = cancel;

				// select grid row
				vm.selectRow = selectRow;

				// update content
				vm.update = update;

				// toggle category in categorization
				vm.toggleCategory = toggleCategory;

				init();

				/////////

				function init() {

				}


				function selectRow(item, form) {

					// prevent switching to another item when form dirty
					if (!form.$pristine && !form.$valid)
						return;

					// already selected
					if (item.selected)
						return;

					loadContent(item);

				}


				function loadContent(item) {

					//$rootScope.profileContentLoading++;

					var params = {};

					editorService.loadForEdit(contentType, 'update', item.id, params)
						.then(function (response) {
							clearItems();
							item.selected = true;
							vm.editingContent = response.data.results;
						})
					.finally(function () {
						//$rootScope.profileContentLoading--;
					});

				}


				function cancel(form) {

					vm.searchResults = angular.copy(vm.modelBackup);
					vm.editingContent = null;
					form.$setPristine();

				}

				function update(form) {

					if (!form.$pristine && !form.$valid)
						return;

					$rootScope.profileContentLoading++;

					return editorService.update(contentType, 'update', vm.editingContent)
							.then(updateCompleted)
							.catch(updateFailed)
							.finally(updateFinally);

					function updateCompleted(response) {
						alertService.add('success', 'Spremembe vsebine so shranjene.', true);
						clearItems();
						vm.editingContent = null;
						return response;
					}

					function updateFailed(error) {
						alertService.add('danger', 'Prišlo je do napake pri shranjevanju sprememb.');
						return $q.reject(error);
					}

					function updateFinally() {
						$rootScope.profileContentLoading--;
					}
				}


				function search(query) {
					var params = { searchQuery: query };

					// include unpublished content
					params.includeUnpublished = true;

					contentService.getContentList(contentType, listMethodName, params)
						.then(function (response) {

							vm.searchResults = response.data.results;
							vm.modelBackup = angular.copy(vm.searchResults);
							vm.editingContent = null;

							// if only one result select it for nice UX
							if (vm.searchResults.length === 1)
								loadContent(vm.searchResults[0]);

						});
				}


				function clearItems() {
					for (var i = 0; i < vm.searchResults.length; i++) {
						vm.searchResults[i].selected = false;
					}
				}


				function toggleCategory(category, form) {

					// expand only don't toggle selected
					if (category.categories.length && !category.expanded && category.selected)
						category.expanded = true;
					// deselect category and its descendants
					else if (category.expanded && category.selected) {
						deselectCategory(category);
						category.expanded = false;
						form.$setDirty();
					}
					// select category and its descendants
					else if (category.expanded && !category.selected) {
						selectCategory(category);
						form.$setDirty();
					}
					else {
						category.selected = !category.selected;
						category.expanded = true;
						form.$setDirty();
					}

				}

				function deselectCategory(category) {

					category.selected = false;
					for (var i = 0; i < category.categories.length; i++)
						deselectCategory(category.categories[i]);
				}

				function selectCategory(category) {

					category.selected = true;
					for (var i = 0; i < category.categories.length; i++)
						selectCategory(category.categories[i]);
				}

			}]);
})();