(function () {

	'use strict';

	angular.module('app').controller('RouteDisplayController', RouteDisplayController);

	RouteDisplayController.$inject = ['$rootScope', '$scope', '$filter', '$location', '$stateParams', '$state', '$log',
		'localStorageService', 'alertService', 'utilsService', 'contentService', 'poisService', 'routesService', 'mapService', 'CONTENT_TYPES'];

	function RouteDisplayController($rootScope, $scope, $filter, $location, $stateParams, $state, $log,
		localStorageService, alertService, utilsService, contentService, poisService, routesService, mapService, CONTENT_TYPES) {

		var logger = $log.getInstance($scope.controllerName);

		var imageIcon = '';
		if (utilsService.userAgentAtLeastIE11())
			imageIcon = 'App_Js/assets/images/markers/png/dot';	// PNG
		else
			imageIcon = 'App_Js/assets/images/markers/svg/dot';	// SVG

		var vm = this;
		vm.route = {};
		vm.elevationData = null;

		// paging
		vm.pageSize = 4;

		vm.promotedPoisPage = 1;
		vm.promotedPois = {};
		vm.promotedPois.loading = false;
		vm.promotedPois.total = 0;
		vm.promotedPois.pageStart = 1;
		vm.promotedPois.pageEnd = vm.pageSize;

		// paging linki
		vm.promotedPoisPrevPageLink = '';
		vm.promotedPoisNextPageLink = '';


		vm.routesClosePage = 1;
		vm.routesClose = {};
		vm.routesClose.loading = false;
		vm.routesClose.total = 0;
		vm.routesClose.pageStart = 1;
		vm.routesClose.pageEnd = vm.pageSize;

		// paging linki
		vm.routesClosePrevPageLink = '';
		vm.routesCloseNextPageLink = '';


		vm.getRoute = getRoute;

		vm.query = {};
		vm.queryString = {};

		vm.nextPromotedPois = function () {

			vm.promotedPoisPage++;
			changePromotedPoisPage();
		};

		vm.prevPromotedPois = function () {

			if (vm.promotedPoisPage > 1)
				vm.promotedPoisPage--;

			changePromotedPoisPage();
		};

		vm.nextRoutesClose = function () {

			vm.routesClosePage++;
			changeRoutesClosePage();
		};

		vm.prevRoutesClose = function () {

			if (vm.routesClosePage > 1)
				vm.routesClosePage--;

			changeRoutesClosePage();
		};

		vm.onMarkerClick = function (feature) {
			$rootScope.$broadcast("markerClicked", feature);
		};



		init();



		/////////

		function init() {

			vm.route.id = $stateParams.id;
			vm.route.slug = $stateParams.slug;

			vm.query.top = vm.pageSize;
			vm.query.excludeIds = vm.route.id;


			return getRoute($stateParams.id).then(function () {

				if (!vm.route.skipChart)
					getElevation();

				drawFeature(vm.route);

				$scope.$on('mapQueryChanged', mapQueryChanged);

				$scope.$on('ggSlideshowSlideChanged', function (event, index, prevIndex) {
					downplayImage(prevIndex);
					highlightImage(index);
				});

				$scope.$on('ggSlideshowThumbHoverEntered', function (event, index) {
					$rootScope.$broadcast('downplayAllFeatures', 'images');
					highlightImage(index);
				});

				$scope.$on('ggSlideshowThumbHoverLeft', function (event, index) {
					$rootScope.$broadcast('downplayAllFeatures', 'images');
				});

			});
		}

		function highlightImage(index) {

			if (!vm.imagesLayer.features || !vm.imagesLayer.features.length) {
				vm.imagesLayer = mapService.getLayer('images');
			}

			var imageFeature;

			if (vm.imagesLayer.features.length > index)
				imageFeature = vm.imagesLayer.features[index];

			if (imageFeature)
				$rootScope.$broadcast('highlightFeature', imageFeature);

		}

		function downplayImage(index) {

			if (!vm.imagesLayer.features || !vm.imagesLayer.features.length) {
				vm.imagesLayer = mapService.getLayer('images');
			}

			var imageFeature;

			if (vm.imagesLayer.features.length > index)
				imageFeature = vm.imagesLayer.features[index];

			if (imageFeature)
				$rootScope.$broadcast('downplayFeature', imageFeature);

		}

		function drawFeature(feature) {

			// map layer content item
			mapService.drawLines([feature], 'selected_route', undefined, true, true, false, false, contentService.getMarkerIcon(CONTENT_TYPES.routes), '', true);

			// map layer content item images
			vm.imagesLayer = mapService.prepareMapLayer(feature.images, 'images', poisService.getLayerType(), imageIcon, false, 10);

			$rootScope.$broadcast("featureDisplayed", feature);
		}

		function mapQueryChanged(scope, mapQuery) {

			if (mapQuery) {
				mapService.mapQueryToQuery(mapQuery, vm.query, null);

				// sprememba karte resetira poisClose in routesClose paging
				resetPaging();

				// zahtevaj nove
				getPromotedContent();
				getCloseRoutes();
			}
		}


		function resetPaging() {
			vm.routesClosePage = 1;
			vm.promotedPoisPage = 1;
			vm.query.skip = 0;
			vm.query.top = vm.pageSize;
		}

		function getRoute(id) {

			$rootScope.loadingContent++;

			return contentService.getItem(CONTENT_TYPES.routes, id)
				.then(function (data) {

					// page title
					$state.current.data.pageTitle = data.title + ' - ' + $state.current.data.pageTitle;

					// page url
					$state.current.data.pageUrl = '//www.geago.eu/' + data.insiteLink;

					// meta desc
					$state.current.data.metaDescription = data.abstract;

					// page image
					$state.current.data.pageImageUrl = data.prominentImage.url;

					// prepare viewmodel
					routesService.prepareItemViewModel(data, true, 1);

					vm.route = data;
					return vm.route;
				})
				.finally(function () {
					$rootScope.loadingContent--;
				});
		}

		function getElevation() {
			routesService.getElevation(vm.route.id, 200).then(function (data) {
				vm.elevationData = data;
			});
		}


		function getPromotedContent() {

			var query = jQuery.extend({}, vm.query);
			vm.promotedPois.loading = true;

			return routesService.getPromotedContent(vm.route.id, query, 'promotedPois').then(function (response) {

				for (var i = 0; i < response.data.results.length; i++) {

					poisService.prepareItemViewModel(response.data.results[i], false, i + 1);

				}

				vm.promotedPois.items = response.data.results;
				vm.promotedPois.total = response.data.total;

				// paging linki
				vm.promotedPoisPrevPageLink = response.data.prevPageLink;
				vm.promotedPoisNextPageLink = response.data.nextPageLink;

				mapService.drawPoints(vm.promotedPois.items, 'promoted', undefined, true, contentService.getMarkerIcon(CONTENT_TYPES.pois), true);

				$location.search(vm.queryString);

			})
			.finally(function () {
				vm.promotedPois.loading = false;
			});

		}

		function getCloseRoutes() {

			vm.routesClose.loading = true;

			return contentService.getContentList(CONTENT_TYPES.routes, 'list', vm.query).then(function (response) {

				for (var i = 0; i < response.data.results.length; i++) {

					routesService.prepareItemViewModel(response.data.results[i], false, i + 1);

				}

				vm.routesClose.items = response.data.results;
				vm.routesClose.total = response.data.total;

				// paging linki
				vm.routesClosePrevPageLink = response.data.prevPageLink;
				vm.routesCloseNextPageLink = response.data.nextPageLink;

				mapService.drawLines(vm.routesClose.items, 'routes_close', undefined, true, true, false, false, contentService.getMarkerIcon(CONTENT_TYPES.routes), '', true);

				$location.search(vm.queryString);

			})
			.finally(function () {
				vm.routesClose.loading = false;
			});
		}


		function changePromotedPoisPage() {

			vm.query.skip = vm.pageSize * (vm.promotedPoisPage - 1);
			vm.query.top = vm.pageSize;

			if (vm.promotedPoisPage > 1) {

				var pageEnd = vm.pageSize * vm.promotedPoisPage;
				if (pageEnd > vm.promotedPois.total)
					vm.promotedPois.pageEnd = vm.promotedPois.total;
				else
					vm.promotedPois.pageEnd = pageEnd;

				vm.promotedPois.pageStart = (vm.promotedPoisPage - 1) * vm.pageSize + 1;
			}
			else {
				vm.promotedPois.pageStart = 1;
				vm.promotedPois.pageEnd = vm.pageSize;
			}

			getPromotedContent();
		}


		function changeRoutesClosePage() {

			vm.query.skip = vm.pageSize * (vm.routesClosePage - 1);
			vm.query.top = vm.pageSize;

			if (vm.routesClosePage > 1) {

				var pageEnd = vm.pageSize * vm.routesClosePage;
				if (pageEnd > vm.routesClose.total)
					vm.routesClose.pageEnd = vm.routesClose.total;
				else
					vm.routesClose.pageEnd = pageEnd;

				vm.routesClose.pageStart = (vm.routesClosePage - 1) * vm.pageSize + 1;
			}
			else {
				vm.routesClose.pageStart = 1;
				vm.routesClose.pageEnd = vm.pageSize;
			}

			getCloseRoutes();
		}

	}
})();