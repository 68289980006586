(function () {

	'use strict';

	angular.module('app.services').factory('poisService', poisService);

	poisService.$inject = ['$http', '$log', '$q', '$state', '$translate',
		'serviceBase', 'mapService', 'utilsService', 'contentService'];

	function poisService($http, $log, $q, $state, $translate,
		serviceBase, mapService, utilsService, contentService) {

		var logger = $log.getInstance('poisService');

		var contentType = 'pois';
		var layerType = 'point';
		var api = serviceBase.geagoApiUrlv2() + '/' + contentType;

		var service = {
			getContentType: getContentType,
			getLayerType: getLayerType,
			prepareItemViewModel: prepareItemViewModel
		};

		return service;		

		function getContentType() {
			return contentType;
		}


		function getLayerType() {
			return layerType;
		}


		function prepareItemViewModel(item, isSelected, order) {

			// already ran prepareVm?
			if (item.displayUrl)
				return;

			// display URL-ji (preko state providerja)
			item.displayUrl = $state.href('layout.public.pois.display', { id: item.id, slug: item.slug }, {});

			// ikone kategorij
			for (var j = 0; j < item.categories.length; j++) {
				var categoryId = item.categories[j].id;
				item.categories[j].iconClass = contentService.getCategoryIcon(categoryId);
			}

			// indexes (UI zadeva za slideshowe ipd.)
			if (item.images && item.images.length) {
				for (var i = 0; i < item.images.length; i++) {
					item.images[i].index = i;
				}
			}

			if (order !== undefined)
				item.order = order;

			// mark as selected
			item.selected = isSelected;

			return item;
		}
	}
})();