(function () {

	'use strict';

	angular.module('app').controller('StoryEditorController', StoryEditorController);

	StoryEditorController.$inject = ['$rootScope', '$scope', '$stateParams', '$state', '$log', '$q',
		'localStorageService', 'storiesService', 'EDITOR_MODES', 'alertService', 'poisService', 'routesService', 'filesService', 'contentService', 'CONTENT_TYPES'];

	function StoryEditorController($rootScope, $scope, $stateParams, $state, $log, $q,
		localStorageService, storiesService, EDITOR_MODES, alertService, poisService, routesService, fileService, contentService, CONTENT_TYPES) {

		var logger = $log.getInstance($scope.controllerName);

		var vm = this;
		vm.contentType = 'story';
		vm.story = {};
		vm.story.selectedPois = [];
		vm.story.selectedRoutes = [];
		vm.story.selectedPartners = [];
		vm.story.images = [];
		vm.story.attachedFiles = [];

		vm.getStory = getStory;
		vm.publish = publish;
		vm.saveDraft = saveDraft;

		// POI selector
		vm.searchPois = searchPois;
		vm.onPoiSelect = onPoiSelect;
		vm.removeSelectedPoi = removeSelectedPoi;

		// route selector
		vm.searchRoutes = searchRoutes;
		vm.onRouteSelect = onRouteSelect;
		vm.removeSelectedRoute = removeSelectedRoute;

		// sponsors selector
		vm.searchPartners = searchPartners;
		vm.onPartnerSelect = onPartnerSelect;
		vm.removeSelectedPartner = removeSelectedPartner;

		if (!vm.initialized)
			init();

		// prijava na broadcaste
		$scope.$on('filesService_uploadsFinished', refreshImageList);
		$scope.$on('filesService_fileDeleted', onDeleteFromImageList);



		/////////

		function init() {

			vm.editorMode = $state.current.data.editorMode;

			if (vm.editorMode === EDITOR_MODES.edit) {

				$rootScope.profileContentLoading++;

				return getStory($stateParams.id)
					.then(function () {
						logger.debug('loaded story for edit');
					})
					.finally(function () {
						$rootScope.profileContentLoading--;
					});
			}

			vm.initialized = true;
		}

		function refreshImageList() {

			var uploadedImages = fileService.getFiles();

			// priredim vrednosti iz image.id v image.imageId, ker to uporabljam na serverju v drugem dtoju
			for (var i = 0; i < uploadedImages.length; i++) {
				if (i in uploadedImages) {
					var img = uploadedImages[i];
					if (!img.imageId)
						img.imageId = img.id;
					vm.story.images.push(img);
				}
			}
			fileService.clearFiles();
		}

		function onDeleteFromImageList(event, file) {

			var i = vm.story.images.length;
			while (i) {
				i -= 1;
				if (vm.story.images[i] === file) {
					vm.story.images.splice(i, 1);
				}
			}
		}


		function getStory(id) {

			return contentService.getItem(CONTENT_TYPES.stories, id)
				.then(function (data) {
					vm.story = data;
					return vm.story;
				});
		}

		function publish(story) {

			story.published = true;

			$rootScope.profileContentLoading++;

			if (vm.editorMode === EDITOR_MODES.edit)
				return storiesService.update(story)
					.then(publishCompleted)
					.catch(publishFailed)
					.finally(updateFinally);
			else
				return storiesService.create(story)
					.then(publishCompleted)
					.catch(publishFailed)
					.finally(updateFinally);

			function publishCompleted(response) {
				alertService.add('success', 'Vaš zapis je shranjen in objavljen.', true);
				$state.go('layout.profile.stories', { lang: 'sl' }, { reload: true, inherit: false });
				return response;
			}

			function publishFailed(error) {
				alertService.add('danger', 'Prišlo je do napake pri shranjevanju zapisa.');
				return $q.reject(error);
			}
		}


		function saveDraft(story) {
			story.published = false;

			$rootScope.profileContentLoading++;

			if (vm.editorMode === EDITOR_MODES.edit)
				return storiesService.update(story)
					.then(saveDraftCompleted)
					.catch(saveDraftFailed)
					.finally(updateFinally);
			else
				return storiesService.create(story)
					.then(saveDraftCompleted)
					.catch(saveDraftFailed)
					.finally(updateFinally);


			function saveDraftCompleted(response) {
				alertService.add('info', 'Vaš zapis je shranjen kot osnutek.', true);
				$state.go('layout.profile.stories', { lang: 'sl'}, { reload: true, inherit: false });
				return response;
			}

			function saveDraftFailed(error) {
				alertService.add('danger', 'Prišlo je do napake pri shranjevanju zapisa.');
				return $q.reject(error);
			}
		}


		function updateFinally() {
			$rootScope.profileContentLoading--;
		}


		// POI selector

		function searchPois(query) {

			var params = {
				top: 10,
				searchquery: query
			};

			return contentService.autocomplete(CONTENT_TYPES.pois, params)
				.then(function (response) {
					return response.data.results;
				});
		}

		function onPoiSelect(item, model, label) {
			if (!vm.story.selectedPois)
				vm.story.selectedPois = [];

			vm.story.selectedPois.push(item);
			model = null;
		}

		function removeSelectedPoi(poi) {
			return removeSelectedPoiIdx(vm.story.selectedPois.indexOf(poi));
		}

		function removeSelectedPoiIdx(index) {
			return vm.story.selectedPois.splice(index, 1);
		}


		// route selector
		function searchRoutes(query) {

			var params = {
				top: 10,
				searchquery: query
			};

			return contentService.autocomplete(CONTENT_TYPES.routes, params)
				.then(function (response) {
					return response.data.results;
				});
		}

		function onRouteSelect(item, model, label) {
			if (!vm.story.selectedRoutes)
				vm.story.selectedRoutes = [];

			vm.story.selectedRoutes.push(item);
			model = null;
		}

		function removeSelectedRoute(Route) {
			return removeSelectedRouteIdx(vm.story.selectedRoutes.indexOf(Route));
		}

		function removeSelectedRouteIdx(index) {
			return vm.story.selectedRoutes.splice(index, 1);
		}


		// sponsor/partner selector
		function searchPartners(query) {

			var params = {
				top: 10,
				searchquery: query
			};

			return contentService.autocomplete(params, 'partners')
				.then(function (response) {
					return response.data.results;
				});
		}

		function onPartnerSelect(item, model, label) {
			if (!vm.story.selectedPartners)
				vm.story.selectedPartners = [];

			vm.story.selectedPartners.push(item);
			model = null;
		}

		function removeSelectedPartner(partner) {
			return removeSelectedPartnerIdx(vm.story.selectedPartners.indexOf(partner));
		}

		function removeSelectedPartnerIdx(index) {
			return vm.story.selectedPartners.splice(index, 1);
		}

	}
})();