(function () {

	'use strict';

	angular.module('app.services').factory('storiesService', storiesService);

	storiesService.$inject = ['$http', '$log', '$q', '$state', '$translate', 'serviceBase', 'utilsService', 'contentService'];

	function storiesService($http, $log, $q, $state, $translate, serviceBase, utilsService, contentService) {

		var logger = $log.getInstance('storiesService');

		var contentType = 'stories';
		var layerType = 'point';
		var api = serviceBase.geagoApiUrlv2() + '/' + contentType;


		var service = {
			getContentType: getContentType,
			getLayerType: getLayerType,
			create: create,
			update: update,
			getFilters: getFilters,
			prepareItemViewModel: prepareItemViewModel
		};

		return service;		


		function getContentType() {
			return contentType;
		}


		function getLayerType() {
			return layerType;
		}


		function create(story) {
			return $http
				.post(api, story)
				.then(createCompleted)
				.catch(createFailed);

			function createCompleted(response) {
				return response.data.results;
			}

			function createFailed(error) {
				$log.error('XHR Failed for create: ' + error.data);
				return $q.reject(error);
			}
		}


		function update(story) {
			return $http
				.put(api + '/' + story.id, story)
				.then(updateCompleted)
				.catch(updateFailed);

			function updateCompleted(response) {
				return response.data.results;
			}

			function updateFailed(error) {
				$log.error('XHR Failed for update: ' + error.data);
				return $q.reject(error);
			}
		}


		function prepareItemViewModel(item, isSelected, order) {

			// already ran prepareVm?
			if (item.displayUrl)
				return;

			// display URL-ji (preko state providerja)
			item.displayUrl = $state.href('layout.public.stories.display', { id: item.id, slug: item.slug }, {});

			// indexes (UI zadeva za slideshowe ipd.)
			if (item.images && item.images.length) {
				for (var i = 0; i < item.images.length; i++) {
					item.images[i].index = i;
				}
			}

			if (order !== undefined)
				item.order = order;

			// mark as selected
			item.selected = isSelected;

			return item;
		}


		function getFilters(params) {

			var d = $q.defer();
			d.resolve();
			return d.promise;

		}

	}
})();