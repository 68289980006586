(function () {

	'use strict';

	angular.module('app').config(['$stateProvider', function ($stateProvider) {

		$stateProvider.state('layout.public.pois', {
			url: "/pois",
			reloadOnSearch: false,
			data: {
				pageTitle: 'Turistična ponudba',
				pageUrl: '//www.geago.eu/pois',
				metaDescription: 'Iščite med več kot 8000 interesnimi točkami (POI) po Sloveniji',
				layoutClass: 'browse pois'
			},
			views: {
				'content@layout.public': {
					templateUrl: 'App_Js/app/pois/poisBrowse.html',
					controller: 'PoisBrowseController as vm'
				}
				//,'site-search@layout': {
				//	templateUrl: 'App_Js/app/pois/site-search.html',
				//	controller: 'PoisBrowseController as vm'
				//}
			}
		});

		$stateProvider.state('layout.public.pois.display', {
			url: "/:id:int/:slug",
			data: {
				pageTitle: 'Turistična ponudba',
				layoutClass: 'display pois'
			},
			views: {
				'content@layout.public': {
					templateUrl: 'App_Js/app/pois/poiDisplay.html',
					controller: 'PoiDisplayController as vm'
				}
			}
		});
	}]);
})();