(function () {

	'use strict';

	angular.module('app').controller('CategorizationEditorCtrl',
		['$rootScope', '$scope', '$stateParams', '$state', '$q', 'categorizationService', 'alertService',
			function ($rootScope, $scope, $stateParams, $state, $q, categorizationService, alertService) {

			var vm = this;

			vm.categorization = {};
			vm.modelBackup = {};

			vm.toggle = toggle;

			// cancel editing, revert to model backup
			vm.cancel = cancel;

			// update categorization
			vm.update = update;

			init();

			/////////

			function init() {

				$rootScope.profileContentLoading++;

				var params = {};
				params.includeTranslations = true;

				categorizationService.getCategorization(params)
					.then(function (response) {
						vm.categorization = response.data.results;
						vm.modelBackup = angular.copy(vm.categorization);
					})
				.finally(function () {
					$rootScope.profileContentLoading--;
				});
			}

			function toggle(node, form) {

				if (!form.$pristine && !form.$valid)
					return;

				node.expanded = !node.expanded;
				selectForEdit(node);

			}

			function selectForEdit(node) {

				vm.editingNode = node;

			}

			function cancel(form) {

				vm.categorization = angular.copy(vm.modelBackup);
				vm.editingNode = null;
				form.$setPristine();

			}

			function update(form) {

				if (!form.$pristine && !form.$valid)
					return;

				$rootScope.profileContentLoading++;

				return categorizationService.updateCategorization(vm.categorization)
						.then(updateCompleted)
						.catch(updateFailed)
						.finally(updateFinally);

				function updateCompleted(response) {
					alertService.add('success', 'Spremembe v kategorizaciji smo shranili.', true);
					//$state.go('^', null, { reload: true, inherit: false });
					return response;
				}

				function updateFailed(error) {
					alertService.add('danger', 'Prišlo je do napake pri shranjevanju kategorizacije.');
					return $q.reject(error);
				}
			}

			function updateFinally() {
				$rootScope.profileContentLoading--;
			}

		}]);
})();