(function () {

	'use strict';

	angular.module('app.services.utils', []).factory('utilsService', utilsService);

	function utilsService() {

		var Strings = {};

		Strings.orEmpty = function (entity) {
			return entity || "";
		};

		var service = {
			Strings: Strings,
			userAgentAtLeastIE11: userAgentAtLeastIE11
		};


		function userAgentAtLeastIE11() {
			return !!(navigator.userAgent.match(/Trident/) && !navigator.userAgent.match(/MSIE/));
		}


		return service;
	}
})();