(function () {

	'use strict';

	angular.module('app').controller('StoriesBrowseController', StoriesBrowseController);

	StoriesBrowseController.$inject = ['$rootScope', '$scope', '$log', '$filter', '$location',
		'$stateParams', '$state', '$controller', 'storiesService', 'contentService', 'mapService', 'MapLayer', 'CONTENT_TYPES'];

	function StoriesBrowseController($rootScope, $scope, $log, $filter, $location,
		$stateParams, $state, $controller, storiesService, contentService, mapService, MapLayer, CONTENT_TYPES) {


		var vm = this;
		vm.contentType = CONTENT_TYPES.stories;

		var controller = $controller('ListControllerBase', {
			$rootScope: $rootScope, $scope: $scope, $log: $log, $filter: $filter, $location: $location,
			$stateParams: $stateParams, $state: $state,
			contentService: contentService, mapService: mapService, MapLayer: MapLayer, contentTypeService: storiesService, vm: vm
		});

		init();


		//// private

		function init() {

			vm.query = {};
			vm.query.tagFilter = $stateParams.tag;


		}

	}
})();