(function () {

	'use strict';

	angular.module('app').controller('TileController', TileController);

	TileController.$inject = ['$rootScope', '$scope', '$log', '$filter', '$location', '$stateParams'];

	function TileController($rootScope, $scope, $log, $filter, $location, $stateParams) {

		var logger = $log.getInstance($scope.controllerName);

		var vm = this;

		vm.active = false;	// tile active/hovered?

		init();

		function init() {

		}

		vm.onMouseOver = function (feature) {

			vm.active = true;

			if (feature.markerOptions && feature.markerOptions.icon && feature.markerOptions.icon.indexOf('-hover') === -1) {
				feature.markerOptions.icon = feature.markerOptions.icon.replace('.png', '-hover.png');
				feature.markerOptions.animation = 4;//google.maps.Animation.BOUNCE;
				feature.markerOptions.zIndex = 100;
			}
			if (feature.stroke) {
				feature.stroke = feature.layer.defaultHoveredStroke;
				
			}

			feature.markerClass = 'icon marker hover';
		};

		vm.onMouseLeave = function (feature) {

			vm.active = false;

			if (feature.markerOptions && feature.markerOptions.icon) {
				feature.markerOptions.icon = feature.markerOptions.icon.replace('-hover.png', '.png');
				feature.markerOptions.animation = null;
				feature.markerOptions.zIndex = 1;
			}
			if (feature.stroke) {
				feature.stroke = feature.layer.defaultLineStroke;
			}

			feature.markerClass = 'icon marker';
		};

		vm.onMarkerClick = function (feature) {
			$rootScope.$broadcast("markerClicked", feature);
		};
	}
})();