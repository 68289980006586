(function () {

	'use strict';

	angular.module('app').controller('LayoutController', LayoutController);

	LayoutController.$inject = ['$rootScope', '$scope', '$log', '$filter', '$location', '$stateParams', '$state', '$translate', 'mapService'];

	function LayoutController($rootScope, $scope, $log, $filter, $location, $stateParams, $state, $translate, mapService) {
		var vm = this;

		// uporabljeno za layout, kjer je karta v tabu
		vm.onMapTabSelected = function () {
			vm.contentActive = false;
			if (!vm.mapInitialized) {
				$rootScope.$broadcast('initMapInTab');
				vm.mapInitialized = true;
			}
		};

		$scope.$on('mapQueryChanged', mapQueryChanged);
		$scope.$on('featureDisplayed', function () {
			vm.contentActive = true;
			vm.mapActive = false;
		});

		$scope.$on('markerClicked', function () {
			vm.contentActive = false;
			vm.mapActive = true;
		});

		// layout ctrl skrbi za odziv na mapCtrl.mapFeatureClicked event 
		// in razpošlje naprej glede na vsebino in strukturo naše aplikacije
		$scope.$on('mapFeatureClicked', function (event, feature) {

			// photos
			if (feature.fileName) {
				$rootScope.$broadcast('photoOnMapClicked', { id: feature.id, index: feature.index });
				return;
			}

			// content
			if (feature.displayUrl) {

				if (feature.contentType.toLowerCase() === 'pois') {
					$state.go('layout.public.pois.display', { id: feature.id, slug: feature.slug }, {});
				}

				if (feature.contentType.toLowerCase() === 'routes') {
					$state.go('layout.public.routes.display', { id: feature.id, slug: feature.slug }, {});
				}
			}

		});


		// popravi mapQuery v mapService; mapService skrbi koordiniranje zadev s karto
		function mapQueryChanged(scope, mapQuery) {
			mapService.mapQuery = mapQuery;
		}
	}
})();