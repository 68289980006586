(function () {

	angular.module('app.map').factory('MapLayer', function () {

		// Define the constructor function.
		function MapLayer(id, type, order, contentType) {
			this.id = id;
			this.type = type;
			this.features = [];
			this.selectFeature = null;
			this.order = order;
			this.contentType = contentType;

			this.windowOptions = {
				disableAutoPan: true,
				pixelOffset: new google.maps.Size(0, -30),
				boxClass: 'infoBox'
			};

			this.markerOptions = {
				optimized: false,
				zIndex: this.order
			};

			this.selectionMarkerOptions = jQuery.extend({}, this.markerOptions, {
				zIndex: this.order
			});


			if(this.atleastIE11 === undefined)
				this.atleastIE11 = !!(navigator.userAgent.match(/Trident/) && !navigator.userAgent.match(/MSIE/));

			if (this.atleastIE11) {
				this.iconFormat = '.png';
			}
			else {
				this.iconFormat = '.svg';
			}

			if (this.iconFormat === '.png') {
				this.defaultIcon = 'App_Js/assets/images/markers/marker-';
				this.defaultSelectedIcon = { url: 'App_Js/assets/images/markers/png/marker-selected' + this.iconFormat };
			}
			else {
				this.defaultIcon = 'App_Js/assets/images/markers/svg/marker-';
				this.defaultSelectedIcon = { url: 'App_Js/assets/images/markers/svg/marker-selected' + this.iconFormat };
			}

			this.defaultLineStroke = { strokeColor: '#ef4036', strokeOpacity: 0.6, strokeWeight: 3 };
			this.defaultSelectedLineStroke = { strokeColor: '#ffee00', strokeOpacity: 1, strokeWeight: 8 };
			this.defaultHoveredStroke = { strokeColor: '#C91A10', strokeOpacity: 0.9, strokeWeight: 5 };
		}

		MapLayer.prototype.setFeatures = function (features, numberedIcons) {
			for (var i = 0; i < features.length; i++) {
				var feature = features[i];

				switch (this.type.toLowerCase()) {
					case 'point':

						if (feature.spatialTypeName.toLowerCase() !== 'point')
							throw 'Exception: MapLayer is of type \"' + layer.type + '\" but current feature is of type \"' + item.spatialTypeName + '\"';

						this.setMarker(feature, numberedIcons);
						break;
					case 'polyline':

						if (feature.spatialTypeName.toLowerCase() !== 'linestring' && item.spatialTypeName.toLowerCase() !== 'polyline')
							throw 'Exception: MapLayer is of type \"' + layer.type + '\" but current feature is of type \"' + item.spatialTypeName + '\"';

						this.setPolyLineFeature(feature);

						break;
				}

				feature.layer = this;
			}

			this.features = features;
		};

		MapLayer.prototype.selectFeature = function (id) {
			for (var i = 0; i < this.features.length; i++) {
				if (id === this.features[i].id) {
					this.selectFeature = this.features[i];
					if (this.type.toLowerCase() === 'point')
						this.features[i].markerOptions.icon = null;
				}
				else {
					this.features[i].markerOptions.icon = this.defaultIcon + (i + 1) + this.iconFormat;
				}
			}
		};

		MapLayer.prototype.clear = function () {
			if (this.features)
				this.features = [];
		};

		MapLayer.prototype.setMarker = function (feature, numberedIcons) {

			feature.layer = this;

			if (feature.geoJson && !feature.geoJson.type) {
				feature.geoJson = {
					type: "Feature",
					geometry: JSON.parse(feature.geoJson)
				};
				feature.geoJsonPoint = feature.geoJson;
				feature.coordinateString = this.getPointCoordinatesString(feature.geoJsonPoint.geometry.coordinates);
			}

			feature.windowOptions = jQuery.extend({}, this.windowOptions);
			feature.markerOptions = jQuery.extend({}, this.markerOptions);

			// zaporedna št. in markerji na slikah
			feature.markerClass = 'icon marker';
			if (numberedIcons) {
				if (feature.order === undefined)
					throw 'Exception: Missing \"order\" on feature ' + feature.id + ' (' + feature.contentType + ', ' + feature.spatialTypeName + ')';

				feature.markerOptions.icon = this.defaultIcon + feature.order + this.iconFormat;
			}
			else
				feature.markerOptions.icon = this.defaultIcon + this.iconFormat;

			if (feature.selected && feature.spatialTypeName.toLowerCase() === 'point') {
				feature.markerOptions = jQuery.extend({}, this.selectionMarkerOptions);
				feature.markerOptions.icon = this.defaultSelectedIcon;

			}
		};


		MapLayer.prototype.setPolyLineFeature = function (feature) {

			feature.layer = this;

			if (feature.geoJson && typeof feature.geoJson !== 'object') {
				feature.geoJson = {
					type: "Feature",
					geometry: JSON.parse(feature.geoJson)
				};
				feature.geoJsonPoint = {
					type: "Feature",
					geometry: { type: 'Point', coordinates: feature.geoJson.geometry.coordinates[0] }
				};
				feature.pathString = this.getCoordinatesString(feature.geoJson.geometry.coordinates);
				feature.coordinateString = this.getPointCoordinatesString(feature.geoJsonPoint.geometry.coordinates);
			}


			feature.windowOptions = jQuery.extend({}, this.windowOptions);

			feature.stroke = jQuery.extend({}, this.defaultLineStroke);
			feature.zIndex = 0;

			if (feature.selected) {
				feature.stroke = jQuery.extend({}, this.defaultSelectedLineStroke);
				feature.zIndex = 10;
			}

		};


		MapLayer.prototype.getGeoJson = function () {

			var features = [];
			for (var i = 0; i < this.features.length; i++) {
				features.push(this.features[i].geoJson);
			}
			var result = {
				"type": "FeatureCollection",
				"features": features
			};
			return result;
		};

		MapLayer.prototype.getPointCoordinatesString = function (coordinates) {
			return coordinates[1].toString() + ',' + coordinates[0].toString();
		};

		MapLayer.prototype.getCoordinatesString = function (coordinates) {
			var result = '[';
			for (var i = 0; i < coordinates.length; i++) {
				var lat = coordinates[i][1];
				var lon = coordinates[i][0];
				result += '[' + lat.toString() + ',' + lon.toString() + ']';
				if (i < coordinates.length - 1) {
					result += ',';

				}
			}
			result += ']';
			return result;
		};


		MapLayer.prototype.extractStartPointForLineStringFeature = function (feature) {

			if (feature.geoJson && typeof feature.geoJson !== 'object') {
				feature.geoJson = {
					type: "Feature",
					geometry: JSON.parse(feature.geoJson)
				};

				feature.geoJsonPoint = {
					type: "Feature",
					geometry: { type: 'Point', coordinates: feature.geoJson.geometry.coordinates[0] }
				};

				feature.pathString = this.getCoordinatesString(feature.geoJson.geometry.coordinates);
				feature.coordinateString = this.getPointCoordinatesString(feature.geoJsonPoint.geometry.coordinates);
			}

			feature.spatialTypeName = 'Point';

			return feature;
		};

		// Return constructor - this is what defines the actual
		// injectable in the DI framework.
		return (MapLayer);
	});

}());