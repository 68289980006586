(function () {

	'use strict';

	angular.module('app.services').factory('contentService', contentService);

	contentService.$inject = ['$rootScope', '$http', '$log', '$q', '$location', '$translate', 'serviceBase', 'utilsService', 'CONTENT_TYPES'];

	function contentService($rootScope, $http, $log, $q, $location, $translate, serviceBase, utilsService, CONTENT_TYPES) {

		var logger = $log.getInstance('contentService');

		var api = serviceBase.geagoApiUrlv2();

		var service = {
			getMarkerIcon: getMarkerIcon
			, getIndicatorIcon: getIndicatorIcon
			, getItem:getItem
			, autocomplete: autocomplete
			, getContentList: getContentList
			, getFilters: getFilters
			, makeAjaxRequestToUrl: makeAjaxRequestToUrl
			, getCategoryIcon: getCategoryIcon
		};

		return service;

		/////////


		function getMarkerIcon(contentType) {
			if (utilsService.userAgentAtLeastIE11())
				return 'App_Js/assets/images/markers/png/' + contentType + '/' + contentType + '-'; // PNG markerji
			else
				return 'App_Js/assets/images/markers/svg/' + contentType + '/' + contentType + '-'; // SVG markerji
		}


		function getIndicatorIcon() {
			if (utilsService.userAgentAtLeastIE11())
				return 'App_Js/assets/images/markers/png/indicators/indicator';
			else
				return 'App_Js/assets/images/markers/svg/indicators/indicator';
		}

		function getItem(contentType, id, params) {

			if (!params)
				params = {};

			if (!params.language)
				params.language = $translate.use();

			return $http
				.get(api + '/' + contentType + '/' + id, { params: params })
				.then(getItemCompleted)
				.catch(getItemFailed);

			function getItemCompleted(response) {

				return response.data.results;
			}

			function getItemFailed(error) {
				$log.error('XHR Failed for getItem: ' + error.data);
				return $q.reject(error);
			}
		}


		function autocomplete(contentType, params) {

			if (!params)
				params = { top: 12 };

			if (!params.language)
				params.language = $translate.use();

			return $http.get(api + '/' + contentType + '/autocomplete', { params: params })
				.then(getItemsCompleted)
				.catch(getItemsFailed);

			function getItemsCompleted(response) {
				return response;
			}

			function getItemsFailed(error) {
				$log.error('XHR Failed for autocomplete: ' + error.data);
				return $q.reject(error);
			}
		}


		function getContentList(contentType, methodName, params) {

			if (!params)
				params = { top: 12 };

			if (!params.top)
				params.top = 12;

			if (!params.language)
				params.language = $translate.use();

			return $http.get(api + '/' + contentType + '/' + methodName, { params: params })
				.then(getContentListCompleted)
				.catch(getContentListFailed);

			function getContentListCompleted(response) {
				return response;
			}

			function getContentListFailed(error) {
				$log.error('XHR Failed for getContentList: ' + error.data);
				return $q.reject(error);
			}
		}


		function getFilters(contentType, params) {

			if (!params)
				params = {};

			if (!params.language)
				params.language = $translate.use();

			return $http
				.get(api + '/categorization/', { params: params })
				.then(getItemsCompleted)
				.catch(getItemsFailed);

			function getItemsCompleted(response) {
				return response.data;
			}

			function getItemsFailed(error) {
				$log.error('XHR Failed for getFilters: ' + error.data);
				return $q.reject(error);
			}
		}


		function makeAjaxRequestToUrl(url) {

			return $http.get(url)
				.then(makeAjaxRequestToUrlCompleted)
				.catch(makeAjaxRequestToUrlFailed);

			function makeAjaxRequestToUrlCompleted(response) {
				return response;
			}

			function makeAjaxRequestToUrlFailed(error) {
				$log.error('XHR Failed for getContentList: ' + error.data);
				return $q.reject(error);
			}

		}


		function getCategoryIcon(categoryId) {
			switch (categoryId.toLowerCase()) {
				case 'am':
					return 'icon-cab';
				case 'dt':
				case 'dtr':
				case 'accessibility':
					return 'icon-accessibility';
				case 'kl':
				case 'bike':
				case 'mtb':
				case 'cycle':
					return 'icon-bicycle-1';
				case 'ph':
				case 'hike':
				case 'hike-rent':
					return 'icon-address';
				case 'pl':
				case 'trails':
					return 'icon-chart-alt-outline';
				case 'sm':
				case 'ski':
					return 'icon-skiing';
				case 'sp':
					return 'icon-pitch';
					//case 'tk':
					//	return 'icon-school';
					//case 'ts':
					//	return 'icon-shuffle';
					//case 'up':
					//	return 'icon-binoculars';
				case 'zg':
				case 'historic':
					return 'icon-flag';
				case 'kon':
				case 'equestrian':
					return 'icon-horse';
				case 'tgs':
				case 'going-out':
					return 'icon-restaurant';
				case 'tnm':
				case 'acc':
					return 'icon-lodging';
				case 'klt':
				case 'culture':
					return 'icon-town-hall';
				case 'nrv':
				case 'nature':
					return 'icon-tree-1';
				case 'top':
				case 'outdoors':
					return 'icon-cloud-sun';
				case 'tšr':
				case 'activity':
					return 'icon-trail-running-shoe';
				case 'tsp':
				case 'spa':
					return 'icon-waves';
				case 'str':
				case 'services':
				case 'rent-a-car':
				case 'honey':
				case 'craft':
				case 'coach':
					return 'icon-users';
				case 'inf':
					return 'icon-road';
				case 'jvs':
				case 'tic':
					return 'icon-info-circled-alt';
				case 'tourism':
					return 'icon-location';
				default:
					return '';
			}
		}


	}
})();