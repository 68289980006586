(function () {

	'use strict';

	angular.module('app').directive('imgLoadingSpinner', function imgLoadingSpinner() {
		return {
			restrict: 'A',

			link: function (scope, element) {
				element.on('load', function () {
					// pokaže img element in odstrani spinner
					element.removeClass('spinner-hide');
					element.addClass('spinner-show');
					element.parent().find('.img-loading-spinner').remove();
				});
				scope.$watch('ngSrc', function () {
					// skrij img element in pokaži spinner
					// element.parent().append('<span class="img-loading-spinner"></span>');
					element.addClass('spinner-hide');
				});
			}
		};
	});
})();