(function () {

	'use strict';

	angular.module('app').controller('LoginController', LoginController);

	LoginController.$inject = ['$rootScope', '$scope', '$stateParams', '$state', '$log', '$q', '$translatePartialLoader', '$translate',
		'localStorageService', 'alertService', 'authService', 'AUTH_EVENTS'];

	function LoginController($rootScope, $scope, $stateParams, $state, $log, $q, $translatePartialLoader, $translate,
		localStorageService, alertService, authService, AUTH_EVENTS) {

		$translatePartialLoader.addPart('login');

		var logger = $log.getInstance($scope.controllerName);

		var vm = this;

		vm.credentials = {};
		vm.credentials.rememberMe = true;
		vm.login = login;
		vm.loggingIn = false;


		/////////


		function login(credentials) {

			vm.loggingIn = true;

			return authService.login(credentials)
				.then(loginSuccess)
				.then(loadUserProfile)
				.catch(loginFail)
				.finally(finalize);

			function loginSuccess(response) {

				alertService.clear();
				alertService.add('success', 'Prijava uspešna.');
				$rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
				return response;
			}

			function loginFail(error) {

				alertService.clear();
				alertService.add('danger', 'Prišlo je do napake pri prijavi.');
				$rootScope.$broadcast(AUTH_EVENTS.loginFailed);
				return $q.reject(error);
			}

			function loadUserProfile() {

				authService.getAuthenticatedUserProfile()
					.then(success)
					.catch(fail);

				function success(response) {

					// v AppController-ju
					$scope.setCurrentUser(response);

					// redirect
					$state.go('layout.profile.stories', { lang: $translate.use()}, { reload: true, inherit: false });
				}

				function fail(error) {
					alertService.clear();
					alertService.add('danger', 'Napaka pri nalaganju uporabniškega profila.');
					return $q.reject(error);
				}
			}

			function finalize() {
				vm.loggingIn = false;
			}
		}
	}
})();