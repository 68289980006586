(function () {
	'use strict';

	angular.module('app.services.alerts', []).factory('alertService', alertService);

	alertService.$inject = ['$timeout'];

	function alertService($timeout) {
		var service = {
			add: add,
			closeAlert: closeAlert,
			closeAlertIdx: closeAlertIdx,
			clear: clear,
			get: get
		},

        alerts = [];

		return service;

		function add(type, msg, autoClose) {

			alerts.push({
				type: type,
				msg: msg,
				close: function () {
					return closeAlert(this);
				}
			});

			if (autoClose)
				return $timeout(function () {
					closeAlertIdx(0);
				}, 10000);
		}

		function closeAlert(alert) {
			return closeAlertIdx(alerts.indexOf(alert));
		}

		function closeAlertIdx(index) {
			if (alerts.length > index)
				return alerts.splice(index, 1);
		}

		function clear() {
			alerts.splice(0, alerts.length);
		}

		function get() {
			return alerts;
		}
	}
})();