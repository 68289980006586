(function () {

	'use strict';

	angular.module('app').controller('PoisBrowseController', PoisBrowseController);

	PoisBrowseController.$inject = ['$rootScope', '$scope', '$log', '$filter', '$location', '$stateParams', '$state', '$controller', 
		'contentService', 'poisService', 'mapService', 'MapLayer', 'CONTENT_TYPES'];

	function PoisBrowseController($rootScope, $scope, $log, $filter, $location, $stateParams, $state, $controller, 
		contentService, poisService, mapService, MapLayer, CONTENT_TYPES) {
			
		var vm = this;
		vm.contentType = CONTENT_TYPES.pois;

		var controller = $controller('ListControllerBase', {
			$rootScope: $rootScope, $scope: $scope, $log: $log, $filter: $filter, 
			$location: $location, $stateParams: $stateParams, $state: $state, 
			contentService: contentService, mapService: mapService, MapLayer: MapLayer, contentTypeService: poisService, vm: vm
		});

	}
})();