(function () {

	'use strict';

	angular.module('app').config(['$stateProvider', 'EDITOR_MODES', 'USER_ROLES', function ($stateProvider, EDITOR_MODES, USER_ROLES) {

		// user profili (My GeaGo)
		$stateProvider.state('layout.profile.dashboard', {
			url: "/profile",
			templateUrl: "App_Js/app/profile/dashboard/dashboard.html",
			data: {
				pageTitle: 'Dashboard'
			},
			controller: 'DashboardController as vm'
		});

		$stateProvider.state('layout.profile.details', {
			url: "/profile/user",
			templateUrl: "App_Js/app/profile/user/profile.html",
			data: {
				pageTitle: 'Uporabniški podatki'
			},
			controller: 'ProfileController as vm'
		});

		$stateProvider.state('layout.profile.imageupload', {
			url: "/profile/image-upload",
			templateUrl: "App_Js/app/profile/image-upload/image-upload.html",
			data: {
				pageTitle: 'Prenos slik'
			},
			controller: 'ImageUploadController as vm'
		});

		//$stateProvider.state('layout.profile.security', {
		//	url: "/profile/user/security",
		//	templateUrl: "App_Js/app/profile/user/security.html",
		//	data: {
		//		pageTitle: 'Varnost in gesla'
		//	},
		//	controller: 'ProfileController as vm'
		//});

		//$stateProvider.state('layout.profile.perks', {
		//	url: "/profile/user/perks",
		//	templateUrl: "App_Js/app/profile/perks/perks.html",
		//	data: {
		//		pageTitle: 'Ugodnosti'
		//	},
		//	controller: 'ProfileController as vm'
		//});

		//$stateProvider.state('layout.profile.tourist-offer', {
		//	url: "/profile/tourist-offer",
		//	templateUrl: "App_Js/app/profile/tourist-offer/to.html",
		//	data: {
		//		pageTitle: 'Turistična ponudba'
		//	},
		//	controller: 'ProfileController as vm'
		//});

		//$stateProvider.state('layout.profile.routes', {
		//	url: "/profile/routes",
		//	templateUrl: "App_Js/app/profile/routes/routes.html",
		//	data: {
		//		pageTitle: 'Izletne poti'
		//	},
		//	controller: 'ProfileController as vm'
		//});


		// user profili (My GeaGo)
		//$stateProvider.state('users/profile', {
		//	url: "/profile",
		//	templateUrl: "App_Js/app/profile/profile.html",
		//	controller: 'ProfileController as vm',
		//	data: {
		//		authorizedRoles: [USER_ROLES.admin, USER_ROLES.editor, USER_ROLES.author, USER_ROLES.member]
		//	}
		//});

	}]);
})();