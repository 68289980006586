(function () {

	'use strict';

	angular.module('app').controller('RoutesBrowseController', RoutesBrowseController);

	RoutesBrowseController.$inject = ['$rootScope', '$scope', '$log', '$filter', '$location', '$stateParams', '$state', '$controller',
		'contentService', 'routesService', 'mapService', 'MapLayer', 'CONTENT_TYPES'];

	function RoutesBrowseController($rootScope, $scope, $log, $filter, $location, $stateParams, $state, $controller,
		contentService, routesService, mapService, MapLayer, CONTENT_TYPES) {

		var vm = this;
		vm.contentType = CONTENT_TYPES.routes;

		var controller = $controller('ListControllerBase', {
			$rootScope: $rootScope, $scope: $scope, $log: $log, $filter: $filter, $location: $location,
			$stateParams: $stateParams, $state: $state,
			contentService: contentService, mapService: mapService, MapLayer: MapLayer,	contentTypeService: routesService, vm: vm
		});

	}
})();