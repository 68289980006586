(function () {

	'use strict';

	angular.module('app').config(['$stateProvider', function ($stateProvider) {

		$stateProvider.state('layout.public.home', {
			url: '',
			reloadOnSearch: false,
			data: {
				pageTitle: 'Domov',
				pageUrl: '//www.geago.eu',
				metaDescription: 'Vaš ultimativni spletni turistični vodnik',
				layoutClass: 'browse home'
			},
			views: {
				'content': {
					templateUrl: 'App_Js/app/home/home.html',
					controller: 'HomeController as vm'
				}
			}
		});

	}]);
})();