(function () {

	'use strict';

	angular.module('app.services').factory('routesService', routesService);

	routesService.$inject = ['$http', '$log', '$q', '$state', '$translate', 'serviceBase', 'utilsService', 'contentService'];

	function routesService($http, $log, $q, $state, $translate, serviceBase, utilsService, contentService) {

		var logger = $log.getInstance('routesService');

		var contentType = 'routes';
		var layerType = 'polyline';
		var api = serviceBase.geagoApiUrlv2() + '/' + contentType;

		var service = {
			getContentType: getContentType,
			getLayerType: getLayerType,
			getElevation: getElevation,
			prepareItemViewModel: prepareItemViewModel,
			getPromotedContent: getPromotedContent
		};

		return service;


		function getContentType() {
			return contentType;
		}


		function getLayerType() {
			return layerType;
		}


		function getElevation(id, samples) {
			return $http
				.get(serviceBase.elevationServiceUrl() + '/' + id.toString() + '@lay_routes?sampleCount=' + samples.toString())
				.then(getElevationCompleted)
				.catch(getElevationFailed);

			function getElevationCompleted(response) {

				return response;
			}

			function getElevationFailed(error) {
				$log.error('XHR Failed for getElevation: ' + error.data);
				return $q.reject(error);
			}
		}


		function prepareItemViewModel(item, isSelected, order) {

			// already ran prepareVm?
			if (item.displayUrl)
				return;

			// display URL-ji (preko state providerja)
			item.displayUrl = $state.href('layout.public.routes.display', { id: item.id, slug: item.slug }, {});

			// ikone kategorij
			for (var j = 0; j < item.categories.length; j++) {
				var categoryId = item.categories[j].id;
				item.categories[j].iconClass = contentService.getCategoryIcon(categoryId);
			}

			// dolžina
			if (item.pathLength > 1000) {
				item.pathLength = (item.pathLength / 1000).toFixed(1) + ' km';
				item.pathLength = item.pathLength.replace('.', ',');	// TEMP! TODO!
			}
			else
				item.pathLength = item.pathLength + ' m';

			// indexes (UI zadeva za slideshowe ipd.)
			if (item.images && item.images.length) {
				for (var i = 0; i < item.images.length; i++) {
					item.images[i].index = i;
				}
			}

			if (order !== undefined)
				item.order = order;

			item.markerClass = 'icon marker';

			// mark as selected
			item.selected = isSelected;

			return item;
		}


		function getPromotedContent(routeId, params) {

			if (!params.language)
				params.language = $translate.use();

			return $http
				.get(api + '/' + routeId + '/promoted', { params: params })
				.then(getItemsCompleted)
				.catch(getItemsFailed);

			function getItemsCompleted(response) {

				var items = response.data.results;
				return response;
			}

			function getItemsFailed(error) {
				$log.error('XHR Failed for getPromotedContent: ' + error.data);
				return $q.reject(error);
			}

		}


	}
})();