(function () {

	'use strict';

	angular.module('app').controller('PoiDisplayController', PoiDisplayController);

	PoiDisplayController.$inject = ['$rootScope', '$scope', '$filter', '$location', '$stateParams', '$state', '$log',
		'localStorageService', 'alertService', 'utilsService', 'contentService', 'poisService', 'routesService', 'mapService', 'CONTENT_TYPES'];

	function PoiDisplayController($rootScope, $scope, $filter, $location, $stateParams, $state, $log,
		localStorageService, alertService, utilsService, contentService, poisService, routesService, mapService, CONTENT_TYPES) {

		var logger = $log.getInstance($scope.controllerName);

		var imageIcon = '';
		if (utilsService.userAgentAtLeastIE11())
			imageIcon = 'App_Js/assets/images/markers/png/dot';	// PNG
		else
			imageIcon = 'App_Js/assets/images/markers/svg/dot';	// SVG

		var vm = this;
		vm.poi = {};

		// paging
		vm.pageSize = 4;

		vm.poisClosePage = 1;
		vm.poisClose = {};
		vm.poisClose.loading = false;
		vm.poisClose.total = 0;
		vm.poisClose.pageStart = 1;
		vm.poisClose.pageEnd = vm.pageSize;


		vm.poisClosePrevPageLink = '';
		vm.poisCloseNextPageLink = '';

		vm.routesClosePage = 1;
		vm.routesClose = {};
		vm.routesClose.loading = false;
		vm.routesClose.total = 0;
		vm.routesClose.pageStart = 1;
		vm.routesClose.pageEnd = vm.pageSize;


		vm.routesClosePrevPageLink = '';
		vm.routesCloseNextPageLink = '';

		vm.getPoi = getPoi;

		vm.query = {};
		vm.queryString = {};

		vm.imagesLayer = {};


		vm.nextPoisClose = function () {

			vm.poisClosePage++;
			changePoisClosePage();
		};

		vm.prevPoisClose = function () {

			if (vm.poisClosePage > 1)
				vm.poisClosePage--;

			changePoisClosePage();
		};

		vm.nextRoutesClose = function () {

			vm.routesClosePage++;
			changeRoutesClosePage();
		};

		vm.prevRoutesClose = function () {

			if (vm.routesClosePage > 1)
				vm.routesClosePage--;

			changeRoutesClosePage();
		};


		vm.onMarkerClick = function (feature) {
			$rootScope.$broadcast("markerClicked", feature);
		};


		init();



		/////////

		function init() {

			vm.poi.id = $stateParams.id;
			vm.poi.slug = $stateParams.slug;

			vm.query.top = vm.pageSize;
			vm.query.excludeIds = vm.poi.id;

			return getPoi($stateParams.id).then(function () {

				drawFeature(vm.poi);

				$scope.$on('mapQueryChanged', mapQueryChanged);

				$scope.$on('ggSlideshowSlideChanged', function (event, index, prevIndex) {
					downplayImage(prevIndex);
					highlightImage(index);
				});

				$scope.$on('ggSlideshowThumbHoverEntered', function (event, index) {
					$rootScope.$broadcast('downplayAllFeatures', 'images');
					highlightImage(index);
				});

				$scope.$on('ggSlideshowThumbHoverLeft', function (event, index) {
					$rootScope.$broadcast('downplayAllFeatures', 'images');
				});

			});
		}

		function highlightImage(index) {

			if (!vm.imagesLayer.features || !vm.imagesLayer.features.length) {
				vm.imagesLayer = mapService.getLayer('images');
			}

			var imageFeature;

			if (vm.imagesLayer.features.length > index)
				imageFeature = vm.imagesLayer.features[index];

			if (imageFeature)
				$rootScope.$broadcast('highlightFeature', imageFeature);

		}

		function downplayImage(index) {

			if (!vm.imagesLayer.features || !vm.imagesLayer.features.length) {
				vm.imagesLayer = mapService.getLayer('images');
			}

			var imageFeature;

			if (vm.imagesLayer.features.length > index)
				imageFeature = vm.imagesLayer.features[index];

			if (imageFeature)
				$rootScope.$broadcast('downplayFeature', imageFeature);
		}


		function mapQueryChanged(scope, mapQuery) {

			if (mapQuery) {
				mapService.mapQueryToQuery(mapQuery, vm.query, null);

				// sprememba karte resetira poisClose in routesClose paging
				resetPaging();

				// zahtevaj nove
				getClosePois();
				getCloseRoutes();
			}
		}


		function drawFeature(feature) {

			// map layer content item
			mapService.drawPoints([feature], 'selected_poi', undefined, true, contentService.getMarkerIcon(CONTENT_TYPES.pois), true);

			// map layer content item images
			vm.imagesLayer = mapService.prepareMapLayer(feature.images, 'images', poisService.getLayerType(), imageIcon, false, 10);

			$rootScope.$broadcast("featureDisplayed", feature);

		}


		function resetPaging() {
			vm.routesClosePage = 1;
			vm.poisClosePage = 1;
			vm.query.skip = 0;
			vm.query.top = vm.pageSize;
		}

		function getPoi(id) {

			$rootScope.loadingContent++;

			return contentService.getItem(CONTENT_TYPES.pois, id)
				.then(function (data) {

					// page title
					$state.current.data.pageTitle = data.title + ' - ' + $state.current.data.pageTitle;

					// page url
					$state.current.data.pageUrl = '//www.geago.eu/' + data.insiteLink;

					// meta desc
					$state.current.data.metaDescription = data.abstract;

					// page image
					$state.current.data.pageImageUrl = data.prominentImage.url;

					// prepare viewmodel
					poisService.prepareItemViewModel(data, true, 1);

					// refresh vm
					vm.poi = data;

					return vm.poi;

				})
				.finally(function () {
					$rootScope.loadingContent--;
				});
		}

		function getClosePois() {

			vm.poisClose.loading = true;

			return contentService.getContentList(CONTENT_TYPES.pois, 'list', vm.query).then(function (response) {

				for (var i = 0; i < response.data.results.length; i++) {

					poisService.prepareItemViewModel(response.data.results[i], false, i + 1);

				}

				vm.poisClose.items = response.data.results;
				vm.poisClose.total = response.data.total;

				// paging linki
				vm.poisClosePrevPageLink = response.data.prevPageLink;
				vm.poisCloseNextPageLink = response.data.nextPageLink;

				mapService.drawPoints(vm.poisClose.items, 'pois_close', undefined, true, contentService.getMarkerIcon(CONTENT_TYPES.pois), true);

				// update querystring
				$location.search(vm.queryString);

			})
			.finally(function () {
				vm.poisClose.loading = false;
			});

		}

		function getCloseRoutes() {

			vm.routesClose.loading = true;

			return contentService.getContentList(CONTENT_TYPES.routes, 'list', vm.query).then(function (response) {

				for (var i = 0; i < response.data.results.length; i++) {

					routesService.prepareItemViewModel(response.data.results[i], false, i + 1);

				}

				vm.routesClose.items = response.data.results;
				vm.routesClose.total = response.data.total;

				// paging linki
				vm.routesClosePrevPageLink = response.data.prevPageLink;
				vm.routesCloseNextPageLink = response.data.nextPageLink;

				mapService.drawLines(vm.routesClose.items, 'routes_close', undefined, true, true, false, false, contentService.getMarkerIcon(CONTENT_TYPES.routes), '', true);

				$location.search(vm.queryString);

			})
			.finally(function () {
				vm.routesClose.loading = false;
			});

		}


		function changePoisClosePage() {

			vm.query.skip = vm.pageSize * (vm.poisClosePage - 1);
			vm.query.top = vm.pageSize;

			if (vm.poisClosePage > 1) {

				var pageEnd = vm.pageSize * vm.poisClosePage;
				if (pageEnd > vm.poisClose.total)
					vm.poisClose.pageEnd = vm.poisClose.total;
				else
					vm.poisClose.pageEnd = pageEnd;

				vm.poisClose.pageStart = (vm.poisClosePage - 1) * vm.pageSize + 1;
			}
			else {
				vm.poisClose.pageStart = 1;
				vm.poisClose.pageEnd = vm.pageSize;
			}

			getClosePois();
		}


		function changeRoutesClosePage() {

			vm.query.skip = vm.pageSize * (vm.routesClosePage - 1);
			vm.query.top = vm.pageSize;

			if (vm.routesClosePage > 1) {

				var pageEnd = vm.pageSize * vm.routesClosePage;
				if (pageEnd > vm.routesClose.total)
					vm.routesClose.pageEnd = vm.routesClose.total;
				else
					vm.routesClose.pageEnd = pageEnd;

				vm.routesClose.pageStart = (vm.routesClosePage - 1) * vm.pageSize + 1;
			}
			else {
				vm.routesClose.pageStart = 1;
				vm.routesClose.pageEnd = vm.pageSize;
			}

			getCloseRoutes();

		}
	}
})();