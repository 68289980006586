(function () {

	'use strict';

	angular.module('app.map').controller('ZoomController', ['$scope', '$rootScope', function ($scope, $rootScope) {

		$scope.zoomIn = function () {

			$scope.$emit('setZoom', +1);

		};

		$scope.zoomOut = function () {

			$scope.$emit('setZoom', -1);
		};

		$scope.resetMap = function () {

			$scope.$emit('resetMap');
		};

		$scope.geolocate = function () {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(locateSuccess, locateFail, { enableHighAccuracy: true });
			}
			else {
				alert('Vaš brskalnik žal ne podpira geolociranja.');
			}
		};


		// Successful geolocation 
		function locateSuccess(position) {


			var location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
			$scope.$emit('zoomToLocation', location);
		}

		// Unsuccessful geolocation 
		function locateFail(geoPositionError) {
			switch (geoPositionError.code) {
				case 0: // UNKNOWN_ERROR 
					alert('An unknown error occurred, sorry');
					break;
				case 1: // PERMISSION_DENIED 
					alert('Permission to use Geolocation was denied');
					break;
				case 2: // POSITION_UNAVAILABLE 
					alert('Couldnt find you...');
					break;
				case 3: // TIMEOUT 
					alert('The Geolocation request took too long and timed out');
					break;
				default:
			}
		}
	}]);


	angular.module('app.map').controller('GeaGoMapController', ['$scope', '$rootScope', '$timeout', '$location', 'utilsService', 'mapService',
		function ($scope, $rootScope, $timeout, $location, utilsService, mapService) {

			// layout, kjer je karta v tabu
			$scope.$on('initMapInTab', function (event) {
				$timeout(function () {
					vm.init();
					//$scope.$apply();
				}, 50);
			});

			var vm = this;
			vm.layers = mapService.layers;
			vm.visibleRatio = 0.5;
			vm.google = window.google;
			// temp za home2!
			vm.defaultCenter = { latitude: 46.0158103, longitude: 17.6501952 };
			vm.sloCenter = { latitude: 46.1491664, longitude: 14.9860106 };
			vm.defaultZoom = 8;
			vm.closeUpZoom = 12;
			vm.closeUpZoomRoutes = 17;
			vm.suppressBroadcast = true;

			var minZoomLevel = 5;

			vm.onMarkerEnter = function (event, marker, feature) {

				var f = feature;
				if (!f.selected) {
					highlightFeature(f);
				}

				vm.getOrCreateInfoWindow(f, null, event.latLng);

			};

			vm.onMarkerLeave = function (event, marker, feature) {

				var f = feature;
				if (!f.selected) {
					downplayFeature(f);
				}
				if (vm.infoWindow) {
					vm.infoWindow.close();
				}

			};

			vm.onFeatureClick = function (event, marker, feature) {

				$rootScope.$broadcast('mapFeatureClicked', feature);

			};

			vm.onLineEnter = function (event, marker, feature) {

				var f = feature;
				var latLng = event.latLng;

				if (vm.routeMarker) {
					vm.routeMarker.setPosition(latLng);
				}
				else {

					vm.routeMarker = new google.maps.Marker({
						position: latLng,
						map: vm.mapInstance,
						visible: false,
						optimized: false

					});
				}

				vm.getOrCreateInfoWindow(f, vm.routeMarker);
				if (!f.selected) {

					if (feature.stroke) {
						feature.stroke = feature.layer.defaultHoveredStroke;

					}


					highlightFeature(f, true);
				}
			};

			vm.getOrCreateInfoWindow = function (f, marker, position) {
				if (!vm.infoWindow) {
					vm.infoWindow = new google.maps.InfoWindow(angular.extend({}, f.windowOptions));
				}

				if (position) {
					vm.infoWindow.setPosition(position);
				}
				else if (marker) {
					vm.infoWindow.setPosition(marker.position);
				}

				vm.infoWindow.setContent('<div class="infoBoxContent">' + f.title + '</div>');
				vm.infoWindow.open(vm.mapInstance);


			};

			vm.onLineLeave = function (event, marker, feature) {


				if (vm.routeMarker) {
					vm.infoWindow.close();
				}

				var f = feature;
				if (!f.selected) {
					downplayFeature(f, true);
				}

			};

			vm.onCloseClick = function (poi) {
				poi.windowOptions.visible = false;
			};

			vm.markerEvents = {
				mouseover: vm.onMarkerEnter,
				mouseout: vm.onMarkerLeave,
				click: vm.onFeatureClick
			};

			vm.lineEvents = {
				mouseover: vm.onLineEnter,
				mouseout: vm.onLineLeave,
				click: vm.onFeatureClick
			};


			// init se kliče iz link() funkcije v map direktivi
			vm.init = function () {

				setListenersOnScope();

				vm.map = getCustomizedGoogleMapSettings();

				parseQueryString();

			};

			//// private


			var userAgentAtLeastIE11 = utilsService.userAgentAtLeastIE11();
			var iconFormat = 'svg';

			if (userAgentAtLeastIE11) {
				iconFormat = 'png';
			}
			else {
				iconFormat = 'svg';
			}


			function setListenersOnScope() {

				$scope.$on('mapInitialized', mapInitialized);
				$scope.$on('markerClicked', zoomToFeature);
				$scope.$on('featureDisplayed', zoomToFeature);
				$scope.$on('zoomToGeoJson', zoomToGeoJson);
				$scope.$on('elevationPointHovered', showElevationPoint);
				$scope.$on('zoomToLocation', function (event, location) {

					vm.mapInstance.setZoom(vm.closeUpZoom);
					setVisibleCenter(location);

					if (vm.locationMarker) {
						vm.locationMarker.setPosition(location);
						vm.locationMarker.setVisible(true);
					}
					else {
						vm.locationMarker = new google.maps.Marker({
							position: location,
							map: vm.mapInstance,
							optimized: false,
							icon: 'App_Js/assets/images/markers/' + iconFormat + '/marker-elevation.' + iconFormat
						});
					}
				});
				$scope.$on('setLocation', setLocation);
				$scope.$on('setZoom', changeZoom);
				$scope.$on('resetMap', resetMapBounds);

				$scope.$on('highlightFeature', function (event, feature, showInfobox) {
					highlightFeature(feature, showInfobox);
				});

				$scope.$on('downplayFeature', function (event, feature, showInfobox) {
					downplayFeature(feature, showInfobox);
				});

				$scope.$on('downplayAllFeatures', function (event, layerId) {
					downplayAllFeatures(layerId);
				});

				$scope.$on('elevationPointOut', function () {
					if (vm.elevationMarker) {
						vm.elevationMarker.setVisible(false);
					}
				});

				$scope.$on('areaSelected', function (event, feature) {
					if (feature.geoJson) {

						if (feature.geoJson) {
							feature.geoJson = {
								type: "Feature",
								geometry: JSON.parse(feature.geoJson)
							};
						}
						zoomToGeoJson(event, feature.geoJson);

					}
				});
			}


			function getInitialMapSettings() {
				var mapControlTypePosition = google.maps.ControlPosition.LEFT_BOTTOM;
				var mapControlStyle = google.maps.MapTypeControlStyle.DEFAULT;


				if (vm.visibleRatio === 1) {
					mapControlTypePosition = google.maps.ControlPosition.RIGHT_TOP;
					mapControlStyle = google.maps.MapTypeControlStyle.DROPDOWN_MENU;
					vm.defaultCenter = vm.sloCenter;
				}

				return {
					style: google.maps.ZoomControlStyle.SMALL,
					control: {},
					center: vm.defaultCenter.latitude + ',' + vm.defaultCenter.longitude,
					zoom: vm.defaultZoom,
					options: {
						draggable: true,
						scaleControl: true,
						overviewMapControl: true,
						scrollwheel: true,

						mapTypeId: 'GeaGo',
						mapTypeControl: true,
						mapTypeControlOptions: {
							position: mapControlTypePosition,
							style: mapControlStyle,
							mapTypeIds: [
								'GeaGo'
								, google.maps.MapTypeId.ROADMAP
								, google.maps.MapTypeId.SATELLITE
								, google.maps.MapTypeId.HYBRID
								, google.maps.MapTypeId.TERRAIN
							]
						},
						fullscreenControl: false,
						zoomControl: false,
						streetViewControl: false,
						panControl: false
						, styles: [
				{
					"featureType": "road",
					"elementType": "geometry",
					"stylers": [{ "lightness": 100 },
						{ "visibility": "simplified" }]
				},
							{
								"featureType": "road",
								"elementType": "labels",
								"stylers": [{ "visibility": "off" }]
							},
							{ "featureType": "water", "elementType": "geometry", "stylers": [{ "visibility": "on" }, { "color": "#C6E2FF" }] }, {
								featureType: "poi",
								elementType: "labels",
								stylers: [
									{
										visibility: "off"
									}
								]
							}, { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#D1D1B8" }] }]
					},
					events: {
						bounds_changed: function (mapInstance) {

							if (vm.geocoder) {
								var strictBounds = vm.strictBounds;
								var map = vm.mapInstance;
								if (strictBounds.contains(map.getCenter())) {
									return;
								}
								// We're out of bounds - Move the map back within the bounds
								var c = map.getCenter(),
						x = c.lng(),
						y = c.lat(),
						maxX = strictBounds.getNorthEast().lng(),
						maxY = strictBounds.getNorthEast().lat(),
						minX = strictBounds.getSouthWest().lng(),
						minY = strictBounds.getSouthWest().lat();

								if (x < minX) x = minX;
								if (x > maxX) x = maxX;
								if (y < minY) y = minY;
								if (y > maxY) y = maxY;

								map.panTo(new google.maps.LatLng(y, x));
								vm.suppressBroadcast = true;
							}
							//$rootScope.$broadcast('mapBoundsChanged', mapInstance);
						},

						zoom_changed: function () {
							var map = vm.mapInstance;
							if (map.getZoom() < minZoomLevel) {
								map.setZoom(minZoomLevel);
								vm.suppressBroadcast = true;
							}
						},

						idle: function () {

							if (!vm.suppressBroadcast) {
								broadcastMapQuery();
							}
							vm.suppressBroadcast = false;
							if (vm.routeMarker) {
								vm.infoWindow.close();
							}

						}

					}
				};
			}

			function getCustomizedGoogleMapSettings() {
				var mapControlTypePosition = google.maps.ControlPosition.LEFT_BOTTOM;
				var mapControlStyle = google.maps.MapTypeControlStyle.DEFAULT;

				if (vm.visibleRatio === 1) {
					mapControlTypePosition = google.maps.ControlPosition.RIGHT_TOP;
					mapControlStyle = google.maps.MapTypeControlStyle.DROPDOWN_MENU;
					vm.defaultCenter = vm.sloCenter;
				}

				return {
					style: google.maps.ZoomControlStyle.SMALL,
					control: {},
					center: vm.defaultCenter.latitude + ',' + vm.defaultCenter.longitude,
					zoom: vm.defaultZoom,
					options: {
						draggable: true,
						scaleControl: true,
						overviewMapControl: true,
						scrollwheel: true,
						mapTypeControl: true,
						mapTypeControlOptions: {
							position: mapControlTypePosition,
							style: mapControlStyle,
							mapTypeIds: [
								google.maps.MapTypeId.ROADMAP
								, google.maps.MapTypeId.SATELLITE
								, google.maps.MapTypeId.HYBRID
								, google.maps.MapTypeId.TERRAIN
								, 'GeaGo'
							]
						},
						fullscreenControl: false,
						zoomControl: false,
						streetViewControl: false,
						panControl: false
						, styles: [
							{
								"featureType": "road",
								"elementType": "geometry",
								"stylers": [{ "lightness": 100 },
									{ "visibility": "simplified" }]
							},
							{
								"featureType": "road",
								"elementType": "labels",
								"stylers": [{ "visibility": "off" }]
							},
							{ "featureType": "water", "elementType": "geometry", "stylers": [{ "visibility": "on" }, { "color": "#C6E2FF" }] }, {
								featureType: "poi",
								elementType: "labels",
								stylers: [
									{
										visibility: "off"
									}
								]
							}, { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#D1D1B8" }] }]
					},
					events: {
						bounds_changed: function (mapInstance) {

							if (vm.geocoder) {
								var strictBounds = vm.strictBounds;
								var map = vm.mapInstance;
								if (strictBounds.contains(map.getCenter())) {
									return;
								}
								// We're out of bounds - Move the map back within the bounds
								var c = map.getCenter(),
						x = c.lng(),
						y = c.lat(),
						maxX = strictBounds.getNorthEast().lng(),
						maxY = strictBounds.getNorthEast().lat(),
						minX = strictBounds.getSouthWest().lng(),
						minY = strictBounds.getSouthWest().lat();

								if (x < minX) x = minX;
								if (x > maxX) x = maxX;
								if (y < minY) y = minY;
								if (y > maxY) y = maxY;

								map.panTo(new google.maps.LatLng(y, x));
								vm.suppressBroadcast = true;
							}
							//$rootScope.$broadcast('mapBoundsChanged', mapInstance);
						},

						zoom_changed: function () {
							var map = vm.mapInstance;
							if (map.getZoom() < minZoomLevel) {
								map.setZoom(minZoomLevel);
								vm.suppressBroadcast = true;
							}

							// če smo na drugih kartah, ne preklapljaj tipa karte
							if (map.mapTypeId.toLowerCase() === 'terrain' || map.mapTypeId.toLowerCase() === 'roadmap') {
								if (map.getZoom() > 12)
									map.setMapTypeId('terrain');
								else
									map.setMapTypeId('roadmap');
							}

						},

						idle: function () {

							if (!vm.suppressBroadcast) {
								broadcastMapQuery();
							}
							vm.suppressBroadcast = false;
							if (vm.routeMarker) {
								vm.infoWindow.close();
							}

						}

					}
				};
			}

			function mapInitialized(event, map) {

				vm.mapInstance = map;

				vm.mapInstance.mapTypes.set('GeaGo', vm.geaGoMapType);
				vm.mapInstance.setOptions(vm.map.options);

				if (vm.initialGeoJson) {
					zoomToGeoJson(null, vm.initialGeoJson);
					vm.initialGeoJson = null;
				}
				else if (vm.initialZoom && vm.initialCenter) {
					vm.mapInstance.setZoom(vm.initialZoom);
					vm.mapInstance.panTo(vm.initialCenter);

					vm.initialZoom = null;
					vm.initialCenter = null;
				}
				else {
					resetMapBounds();
				}
				vm.suppressBroadcast = false;

				if (!vm.geocoder) {
					var geocoder = new google.maps.Geocoder();
					geocoder.geocode({ 'address': 'slovenia' }, function (results, status) {
						if (status === google.maps.GeocoderStatus.OK) {
							vm.geocoder = geocoder;
							if (!vm.strictBounds) {
								vm.strictBounds = results[0].geometry.viewport;
								var ne = vm.strictBounds.getNorthEast();
								var exdendedNe = new google.maps.LatLng(ne.lat(), ne.lng() + 2);
								vm.strictBounds.extend(exdendedNe);
							}
						}
					});
				}

				map.addListener("bounds_changed", vm.map.events.bounds_changed);
				map.addListener("zoom_changed", vm.map.events.zoom_changed);
				map.addListener("idle", vm.map.events.idle);

				//broadcastMapQuery();

			}

			function resetMapBounds() {
				var center = new google.maps.LatLng(vm.sloCenter.latitude, vm.sloCenter.longitude);
				vm.mapInstance.setZoom(vm.defaultZoom);
				setVisibleCenter(center);
				broadcastMapQuery(true);
			}

			function changeZoom(event, zoomChange) {
				var center = getVisibleCenter();
				var zoom = vm.mapInstance.getZoom();
				zoom += zoomChange;

				// če smo na drugih kartah, ne preklapljaj tipa karte
				if (vm.mapInstance.mapTypeId.toLowerCase() === 'terrain' || vm.mapInstance.mapTypeId.toLowerCase() === 'roadmap') {
					if (zoom > 12)
						vm.mapInstance.setMapTypeId('terrain');
					else
						vm.mapInstance.setMapTypeId('roadmap');
				}

				vm.mapInstance.setZoom(zoom);
				setVisibleCenter(center);
			}

			function highlightFeature(f, noWindow) {

				if (f.spatialTypeName.toLowerCase() === 'point') {
					if (f.markerOptions.icon.indexOf('-hover') === -1) {
						f.markerOptions.icon = f.markerOptions.icon.replace('.' + iconFormat, '-hover.' + iconFormat);
						f.markerClass = 'icon marker hover';
					}
				}

				if (f.stroke) {
					f.stroke = f.layer.defaultHoveredStroke;
					$scope.$apply();
				}

				if (!noWindow)
					f.windowOptions.visible = true;
			}

			function downplayFeature(f, noWindow) {

				if (f.spatialTypeName.toLowerCase() === 'point') {
					f.markerOptions.icon = f.markerOptions.icon.replace('-hover.' + iconFormat, '.' + iconFormat);
					f.markerClass = 'icon marker';
				}

				if (!noWindow)
					f.windowOptions.visible = false;

				if (f.stroke)
					f.stroke = f.layer.defaultLineStroke;
			}

			function downplayAllFeatures(layerId) {

				if (vm.layers) {
					for (var i = 0; i < vm.layers.length; i++) {
						if (vm.layers[i].id === layerId) {
							for (var j = 0; j < vm.layers[i].features.length; j++) {
								var f = vm.layers[i].features[j];
								downplayFeature(f);
							}
						}
					}
				}
			}

			function setLocation(event, params) {

				var zoom = vm.defaultZoom;

				if (params.z)
					zoom = parseInt(params.z);

				if (params.lat && params.lon && zoom) {

					var center = new google.maps.LatLng(params.lat, params.lon);

					if (!vm.mapInstance) {
						vm.initialZoom = zoom;
						vm.initialCenter = center;
					}
					else {
						vm.mapInstance.setZoom(zoom);
						vm.mapInstance.panTo(center);
					}
				}
			}

			function parseQueryString() {
				var zoom = $location.search().z;
				if (zoom)
					zoom = parseInt(zoom);
				else
					zoom = vm.defaultZoom;

				var centerLat = $location.search().lat;
				var centerLon = $location.search().lon;
				if (centerLon && centerLat && zoom) {

					var center = new google.maps.LatLng(centerLat, centerLon);

					if (!vm.mapInstance) {
						vm.initialZoom = zoom;
						vm.initialCenter = center;
					}
					else {
						vm.mapInstance.setZoom(zoom);
						vm.mapInstance.panTo(center);
					}
				}
			}

			function showElevationPoint(event, elevationPoint, minElevation, maxElevation) {

				if (vm.elevationMarker) {
					vm.elevationMarker.setPosition(elevationPoint.location);
					vm.elevationMarker.setVisible(true);
				}
				else {

					vm.elevationMarker = new google.maps.Marker({
						position: elevationPoint.location,
						map: vm.mapInstance,
						optimized: false,
						icon: 'App_Js/assets/images/markers/' + iconFormat + '/marker-elevation.' + iconFormat
					});
				}
			}

			function broadcastMapQuery(force) {

				var bounds = getVisibleBounds();
				var ne = bounds.getNorthEast(); // tole
				var sw = bounds.getSouthWest(); //tole	

				var mapQuery = {

					extmaxlon: ne.lng(),
					extmaxlat: ne.lat(),
					extminlon: sw.lng(),
					extminlat: sw.lat(),
					zoom: vm.mapInstance.getZoom(),
					center: vm.mapInstance.getCenter()
				};

				if (force || !boundsEqual(vm.bounds, bounds) || vm.forceBroadcastMap) {
					vm.bounds = bounds;
					$rootScope.$broadcast('mapQueryChanged', mapQuery);
					vm.forceBroadcastMap = false;
				}
			}

			function boundsEqual(bounds1, bounds2) {
				if (!bounds1 || !bounds2)
					return false;

				var ne1 = bounds1.getNorthEast(); // tole
				var sw1 = bounds1.getSouthWest(); //tole	

				var ne2 = bounds2.getNorthEast(); // tole
				var sw2 = bounds2.getSouthWest(); //tole	

				return ne1.lat() === ne2.lat() && ne1.lng() === ne2.lng() && sw1.lng() === sw2.lng() && sw1.lng() === sw2.lng();

			}

			function getVisibleBounds() {

				var b = vm.mapInstance.getBounds();
				var ne = b.getNorthEast();
				var sw = b.getSouthWest();
				var w = ne.lng() - sw.lng();

				var nne = new google.maps.LatLng(ne.lat(), sw.lng() + w * vm.visibleRatio);
				var result = new google.maps.LatLngBounds(sw, nne);
				return result;
			}

			function zoomToFeature(event, feature) {

				vm.forceBroadcastMap = true;
				zoomToGeoJson(event, feature.geoJson, feature.contentType);

			}

			function zoomToGeoJson(event, geojson, featureType) {

				if (vm.mapInstance) {

					// na trasah hočejo drugačen close up zoom
					if (featureType === 'routes')
						vm.mapInstance.setZoom(vm.closeUpZoomRoutes);

					// če smo na drugih kartah, ne preklapljaj tipa karte
					if (vm.mapInstance.mapTypeId.toLowerCase() === 'terrain' || vm.mapInstance.mapTypeId.toLowerCase() === 'roadmap')
						vm.mapInstance.setMapTypeId('terrain');

					vm.mapInstance.data.addGeoJson(geojson);
					var map = vm.mapInstance;
					var bounds = new google.maps.LatLngBounds();
					map.data.forEach(function (feature) {
						processPoints(feature.getGeometry(), bounds.extend, bounds);
					});

					map.data.forEach(function (feature) {
						map.data.remove(feature);
					});
					fitBounds(bounds);
				}
				else {
					vm.initialGeoJson = geojson;
				}
			}

			function processPoints(geometry, callback, thisArg) {
				if (geometry instanceof google.maps.LatLng) {
					callback.call(thisArg, geometry);
				} else if (geometry instanceof google.maps.Data.Point) {
					callback.call(thisArg, geometry.get());
				} else {
					geometry.getArray().forEach(function (g) {
						processPoints(g, callback, thisArg);
					});
				}
			}

			function fitBounds(bounds) {
				var zoom;
				if (vm.visibleRatio === 1) {
					vm.mapInstance.fitBounds(bounds);
					zoom = vm.mapInstance.getZoom();
					if (zoom > vm.closeUpZoom)
						vm.mapInstance.setZoom(vm.closeUpZoom);
				}
				else {

					var mb = getVisibleBounds();
					var mbr = getRatio(mb);
					var ibr = getRatio(bounds);
					var result;
					var ne = bounds.getNorthEast();
					var sw = bounds.getSouthWest();
					var nne = ne;
					var nsw = sw;
					if (mbr < ibr) {
						var dh = (getHeight(bounds) * (ibr / mbr) - getHeight(bounds)) / 2.0;
						nne = new google.maps.LatLng(ne.lat() + dh, ne.lng());
						nsw = new google.maps.LatLng(sw.lat() - dh, sw.lng());
					}
					else if (ibr < mbr) {
						var dw = (getWidth(bounds) * (mbr / ibr) - getWidth(bounds)) / 2.0;
						nne = new google.maps.LatLng(ne.lat(), ne.lng() + dw);
						nsw = new google.maps.LatLng(sw.lat(), sw.lng() - dw);
					}
					result = new google.maps.LatLngBounds(nsw, nne);
					vm.mapInstance.fitBounds(result);

					zoom = vm.mapInstance.getZoom();
					if (zoom > vm.closeUpZoom)
						vm.mapInstance.setZoom(vm.closeUpZoom);

					setVisibleCenter(bounds.getCenter());
				}
			}

			function drawRectangle(bounds, color) {
				var rectangle = new google.maps.Rectangle({
					strokeColor: color,
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: color,
					fillOpacity: 0.35,
					map: vm.mapInstance,
					bounds: bounds
				});
				return rectangle;

			}

			function drawMarker(point) {
				var marker = new google.maps.Marker({
					position: point,
					map: vm.mapInstance,
					optimized: false

				});

			}

			function getVisibleCenter() {
				var b = getVisibleBounds();
				var c = vm.mapInstance.getCenter();

				var ne = b.getNorthEast();
				var sw = b.getSouthWest();

				var vc = new google.maps.LatLng(c.lat(), sw.lng() + (ne.lng() - sw.lng()) / 2);

				return vc;
			}

			function setVisibleCenter(c) {
				var vc = getVisibleCenter();
				var mc = vm.mapInstance.getCenter();

				var lngDif = mc.lng() - vc.lng();
				var nlng = c.lng() + lngDif;

				var nmc = new google.maps.LatLng(c.lat(), nlng);
				vm.mapInstance.panTo(nmc);
			}

			function getWidth(bounds) {

				var width = Math.abs(bounds.getNorthEast().lng() - bounds.getSouthWest().lng());
				if (width === 0)
					width = 0.01;
				return width;

			}

			function getHeight(bounds) {

				var height = Math.abs(bounds.getNorthEast().lat() - bounds.getSouthWest().lat());
				if (height === 0)
					height = 0.01;
				return height;
			}

			function getRatio(bounds) {
				return getWidth(bounds) / getHeight(bounds);
			}

			function GeaGoMapType() {
			}

			GeaGoMapType.prototype.tileSize = new google.maps.Size(256, 256);
			GeaGoMapType.prototype.maxZoom = 19;

			GeaGoMapType.prototype.getTile = function (coord, zoom, ownerDocument) {

				var url = '//tiles2.luz.si/nukleus71/api/nukleusTiles/v71/LuzMapColorGoogleTiles/agccTile/lay_luzmapcolorGoogle_tiles/' + zoom + '/' + coord.y + '/' + coord.x;
				var img = ownerDocument.createElement('img');
				img.src = url;
				return img;
			};

			GeaGoMapType.prototype.name = 'GeaGo';
			GeaGoMapType.prototype.alt = 'GeaGo map type';

			vm.geaGoMapType = new GeaGoMapType();

		}]);
})();