(function () {

	'use strict';

	angular.module('app').controller('ListControllerBase', ListControllerBase);

	ListControllerBase.$inject = ['$rootScope', '$scope', '$log', '$filter', '$location', '$stateParams', '$state',
		'$anchorScroll',
		'contentService', 'mapService', 'contentTypeService', 'vm'];

	function ListControllerBase($rootScope, $scope, $log, $filter, $location, $stateParams, $state, $anchorScroll,
		contentService, mapService, contentTypeService, vm) {

		// app config puts current controller name into $scope.controllerName
		var logger = $log.getInstance($scope.controllerName);	// instance the logger with controller name for context

		vm.contentType;

		// se uporablja za klice API-jev in lahko vključuje tudi parametre, ki niso v querystringu
		vm.query = {};
		vm.query.top = 12;

		// se uporablja samo za prikaz v querystringu (ponavadi ima manj parametrov kot query)
		vm.queryString = {};

		// paging
		vm.page = 1;
		vm.pageSize = 12;
		vm.prevPageLink = '';
		vm.nextPageLink = '';

		vm.content = {};
		vm.content.loading = false;
		vm.content.total = 0;
		vm.content.pageStart = 1;
		vm.content.pageEnd = vm.pageSize;

		// paging
		vm.nextPage = function () {

			vm.page++;
			vm.queryString.skip = vm.query.skip = vm.pageSize * (vm.page - 1);
			vm.query.top = vm.pageSize;

			refreshContent();

			if (vm.page > 1) {

				var pageEnd = vm.pageSize * vm.page;
				if (pageEnd > vm.content.total)
					vm.content.pageEnd = vm.content.total;
				else
					vm.content.pageEnd = pageEnd;

				vm.content.pageStart = (vm.page - 1) * vm.pageSize + 1;
			}
			else {
				vm.content.pageStart = 1;
				vm.content.pageEnd = vm.pageSize;
			}

			$location.hash('top');
			$anchorScroll();
		};

		vm.previousPage = function () {

			if (vm.page > 1)
				vm.page--;

			vm.queryString.skip = vm.query.skip = vm.pageSize * (vm.page - 1);
			vm.query.top = vm.pageSize;

			refreshContent();

			var pageEnd = vm.pageSize * vm.page;
			if (pageEnd > vm.content.total)
				vm.content.pageEnd = vm.content.total;
			else
				vm.content.pageEnd = pageEnd;

			vm.content.pageStart = (vm.page - 1) * vm.pageSize + 1;

			$location.hash('top');
			$anchorScroll();
		};


		init();


		//// private


		function init() {

			logger.debug('init');

			parseQuerystring();

			$scope.$on('mapQueryChanged', mapQueryChanged);

			if (vm.queryString.lat && vm.queryString.lon)
				$rootScope.$broadcast('setLocation', vm.queryString);
			else {
				vm.zoomOutIfNoResults = true;
				refreshContent();
			}
		}


		function parseQuerystring() {

			// extent
			mapService.parseMapLocationFromQueryString(vm.query, vm.queryString);

			// categories
			var categories = $location.search().categories;
			if (categories)
				vm.query.categories = vm.queryString.categories = categories;

			// paging
			var skip = $location.search().skip;
			if (skip) {
				vm.query.skip = vm.queryString.skip = skip;
				vm.page = (skip / vm.pageSize) + 1;
			}
		}


		function mapQueryChanged(scope, mapQuery) {

			if (mapQuery) {
				mapService.mapQueryToQuery(mapQuery, vm.query, vm.queryString);
				resetPaging();
				refreshContent();
			}
		}


		function getMapQueryFromService() {
			var mapQuery = mapService.mapQuery;
			if (mapQuery)
				mapService.mapQueryToQuery(mapQuery, vm.query, vm.queryString);
		}


		function refreshContent() {

			if (!vm.query.extmaxlat) {
				getMapQueryFromService();
			}
			if (!vm.query.extmaxlat) {
				return;
			}

			vm.content.loading = true;

			(vm.listRequest = contentService.getContentList(vm.contentType, 'list', vm.query)).then(function (response) {

				if (response.data.results.length === 0 && (vm.zoomOutIfNoResults)) {
					$rootScope.$broadcast('setZoom', -1);
				}
				else {

					// totals
					vm.content.total = response.data.total;

					// paging - pageEnd
					if (vm.content.total < vm.page * vm.pageSize)
						vm.content.pageEnd = vm.content.total;

					for (var i = 0; i < response.data.results.length; i++) {

						contentTypeService.prepareItemViewModel(response.data.results[i], false, i + 1);

					}

					vm.content.items = response.data.results;

					// paging linki
					vm.prevPageLink = response.data.prevPageLink;
					vm.nextPageLink = response.data.nextPageLink;

					vm.zoomOutIfNoResults = null;

					// map
					if (contentTypeService.getContentType().toLowerCase() === 'routes') {
						mapService.drawLines(
							vm.content.items,
							contentTypeService.getContentType(),
							undefined,
							true, true, false, false,
							contentService.getMarkerIcon(vm.contentType), '', true);
					}
					else {
						mapService.prepareMapLayer(
							vm.content.items,
							contentTypeService.getContentType(),
							contentTypeService.getLayerType(),
							contentService.getMarkerIcon(vm.contentType),
							true);
					}
				}

				// update querystring
				$location.search(vm.queryString);
				vm.content.loading = false;
			});

		}

		function resetPaging() {

			// reset skip
			vm.queryString.skip = vm.query.skip = 0;
			vm.page = 1;
			vm.query.top = vm.pageSize;
		}
	}
})();