(function () {

	'use strict';

	angular.module('app').controller('StoryDisplayController', StoryDisplayController);

	StoryDisplayController.$inject = ['$rootScope', '$scope', '$filter', '$location', '$stateParams', '$state', '$log',
		'localStorageService', 'alertService', 'utilsService', 'contentService', 'poisService', 'routesService',
		'storiesService', 'mapService', 'CONTENT_TYPES'];

	function StoryDisplayController($rootScope, $scope, $filter, $location, $stateParams, $state, $log,
		localStorageService, alertService, utilsService, contentService, poisService, routesService,
		storiesService, mapService, CONTENT_TYPES) {

		var logger = $log.getInstance($scope.controllerName);

		var vm = this;
		vm.story = {};

		// rabimo jih ločeno zaradi prikaza na karti
		vm.story.selectedPois = [];
		vm.story.selectedRoutes = [];
		vm.story.poisLoading = false;
		vm.story.routesLoading = false;

		// in združene zaradi prikaza v gridu
		vm.story.relatedContent = [];
		vm.story.relatedContentLoading = false;

		vm.getStory = getStory;

		init();

		/////////

		function init() {

			vm.story.id = $stateParams.id;
			vm.story.slug = $stateParams.slug;

			return getStory($stateParams.id).then(function () {
			});
		}

		function getStory(id) {

			$rootScope.loadingContent++;
			vm.story.poisLoading = vm.story.routesLoading = true;

			return contentService.getItem(CONTENT_TYPES.stories, id)
				.then(function (data) {

					// page title
					if(data.title)
						$state.current.data.pageTitle = data.title + ' - ' + $state.current.data.pageTitle;

					// page url
					if(data.insiteLink)
						$state.current.data.pageUrl = '//www.geago.eu/' + data.insiteLink;

					// meta desc
					if(data.seo.metaDescription)
						$state.current.data.metaDescription = data.seo.metaDescription;

					// page image
					if(data.prominentImage && data.prominentImage.url)
						$state.current.data.pageImageUrl = data.prominentImage.url;

					vm.story = data;

					// vms
					for (var i = 0; i < vm.story.selectedPois.length; i++) {

						poisService.prepareItemViewModel(vm.story.selectedPois[i], false, i + 1);

					}

					for (i = 0; i < vm.story.selectedRoutes.length; i++) {

						routesService.prepareItemViewModel(vm.story.selectedRoutes[i], false, i + 1);

					}

					// združi vse vsebine za prikaz v enem tile gridu
					vm.story.relatedContent = vm.story.selectedPois.concat(vm.story.selectedRoutes);

					var poisLayer = mapService.prepareMapLayer(vm.story.selectedPois, 'selectedPois',
						poisService.getLayerType(), contentService.getMarkerIcon(CONTENT_TYPES.pois), true);

					var routesLayer = mapService.prepareMapLayer(vm.story.selectedRoutes, 'selectedRoutes',
						routesService.getLayerType(), contentService.getMarkerIcon(CONTENT_TYPES.routes), true);
					
					var geoJson = poisLayer.getGeoJson();

					var routesGeoJson = routesLayer.getGeoJson();

					if (routesGeoJson)
					{
						geoJson.features = geoJson.features.concat(routesGeoJson.features);
					}					

					$rootScope.$broadcast('zoomToGeoJson', geoJson);

					return vm.story;
				})
				.catch(function (error) {

					if (error.status === 404)
						$state.go('layout.404');
					else
						$state.go('layout.500');

				})
				.finally(function () {
					$rootScope.loadingContent--;
					vm.story.poisLoading = vm.story.routesLoading = false;
				});
		}
	}
})();