(function () {

	'use strict';

	angular.module('app').controller('DashboardController', DashboardController);

	DashboardController.$inject = ['$rootScope', '$scope', '$stateParams', '$state', '$log', '$q',
		'localStorageService', 'alertService', 'authService', 'userProfileService'];

	function DashboardController($rootScope, $scope, $stateParams, $state, $log, $q,
		localStorageService, alertService, authService, profileService) {

		var logger = $log.getInstance($scope.controllerName);

		var vm = this;
		vm.profile = {};

		init();

		/////////

		function init() {

		}
	}
})();