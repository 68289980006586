(function () {

	'use strict';

	angular.module('app').controller('ggFiltersController', ggFiltersController);

	ggFiltersController.$inject = ['$rootScope', '$scope', '$log', '$filter', '$timeout', '$interval', '$translatePartialLoader', 'contentService'];

	function ggFiltersController($rootScope, $scope, $log, $filter, $timeout, $interval, $translatePartialLoader, contentService) {

		$translatePartialLoader.addPart('filters');

		var logger = $log.getInstance($scope.controllerName);

		var vm = this;

		vm.filters = {};
		vm.filters.collapsed = true;

		vm.filters.categories = {};

		vm.filters.active = {};
		vm.filters.activeIds = [];

		// loading spinners
		vm.filters.loading = false;

		vm.toggleLevelFilter = function (id) {
			var category = $filter('getCategoryById')(vm.filters.categories, id);

			// last level, no children
			if (!(category.categories && category.categories.length)) {
				if (category.active)
					category.active = false;
				else
					category.active = true;
				return;
			}

			if (category.active && category.opened) {
				category.active = false;
				category.opened = false;
				turnoffSublevels(category.categories);
				closeSublevels(category.categories);
				return;
			}


			if (category.active && !category.opened) {
				closeSiblings(id, vm.filters.categories);
				category.opened = true;
				return;
			}

			closeSiblings(id, vm.filters.categories);
			category.active = true;
			category.opened = true;
		};

		vm.resetFilters = function () {
			for (var l1 = 0; l1 < vm.filters.categories.length; l1++) {
				vm.filters.categories[l1].opened = vm.filters.categories[l1].active = false;

				for (var l2 = 0; l2 < vm.filters.categories[l1].categories.length; l2++) {
					vm.filters.categories[l1].categories[l2].active = false;

					for (var l3 = 0; l3 < vm.filters.categories[l1].categories[l2].categories.length; l3++) {
						vm.filters.categories[l1].categories[l2].categories[l3].active = false;
					}
				}
			}
		};


		init();


		/////////

		function init() {

			getFilters()
				.then(function (data) {
					$scope.$watch('vm.filters.categories', function (newValue, oldValue) {
						//refreshSearchQuery(newValue, oldValue);
						$rootScope.$broadcast('filtersChanged', vm.filters);
					}, true); // true = watch by value
				})
				.finally(function () {

				});

		}

		function getFilters(params) {

			vm.filters.loading = true;

			return contentService.getFilters(vm.contentType, params)
				.then(function (data) {
					if (data && data.results) {
						vm.filters.categories = data.results;
						for (var f = 0; f < vm.filters.categories.length; f++) {
							vm.filters.categories[f].active = vm.filters.categories[f].opened = false;
							vm.filters.categories[f].icon = contentService.getCategoryIcon(vm.filters.categories[f].id);
						}
					}
					return vm.filters.categories;
				})
				.finally(function () {
					vm.filters.loading = false;
				});
		}

		function closeSublevels(subCategories) {
			for (var f = 0; f < subCategories.length; f++) {
				subCategories[f].opened = false;

				if (subCategories[f].categories && subCategories[f].categories.length)
					closeSublevels(subCategories[f].categories);
			}
		}

		function turnoffSublevels(subCategories) {

			for (var f = 0; f < subCategories.length; f++) {
				if (subCategories[f].active)
					subCategories[f].active = false;

				if (subCategories[f].categories && subCategories[f].categories.length)
					turnoffSublevels(subCategories[f].categories);
			}
		}

		function closeSiblings(id, categories) {

			var siblings = findCategorySiblings(id, categories);
			for (var i = 0; i < siblings.length; i++) {

				siblings[i].opened = false;

			}
		}

		function findCategorySiblings(id, categories) {

			var siblings = [];
			var found = false;

			for (var i = 0; i < categories.length; i++) {

				if (categories[i].id !== id)
					siblings.push(categories[i]);

				if (categories[i].id === id)
					found = true;

				if (!found && categories[i].categories.length) {
					var subsiblings = findCategorySiblings(id, categories[i].categories);
					if(subsiblings && subsiblings.length)
					{
						found = true;
						siblings = subsiblings;
						break;
					}
				}

			}

			if (found)
				return siblings;
		}

	}

	angular.module('app').filter('getCategoryById', function () {
		return function (input, id) {
			var i = 0, len = input.length;
			for (; i < len; i++) {
				// level 1
				if (input[i].id === id) {
					return input[i];
				}
				// level 2
				if (input[i].categories) {
					for (var j = 0; j < input[i].categories.length; j++) {
						if (input[i].categories[j].id === id)
							return input[i].categories[j];

						// level 3
						if (input[i].categories[j].categories) {
							for (var k = 0; k < input[i].categories[j].categories.length; k++) {
								if (input[i].categories[j].categories[k].id === id)
									return input[i].categories[j].categories[k];
							}
						}
					}
				}
			}
			return null;
		};
	});

})();