(function () {

	'use strict';

	angular.module('app').directive('ggNavbar', ['mediaProvider', function navbar(mediaProvider) {
		var directive = {
			restrict: 'AE',
			templateUrl: mediaProvider.selectPartial('App_Js/app/navbar/navbar')	// ne dela!!
		};
		return directive;
	}]);
})();