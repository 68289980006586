(function () {

	'use strict';

	angular.module('app').config(['$stateProvider', function ($stateProvider) {

		$stateProvider.state('layout.contact-sl', {
			url: "/kontakt",
			//controller: 'LoginController as vm',
			templateUrl: 'App_Js/app/contact/contact-sl.html',
			data: {
				pageTitle: 'Kontakt',
				layoutClass: 'contact'
			}
		});

		$stateProvider.state('layout.contact-en', {
			url: "/contact",
			//controller: 'LoginController as vm',
			templateUrl: 'App_Js/app/contact/contact-en.html',
			data: {
				pageTitle: 'Contact',
				layoutClass: 'contact'
			}
		});

	}]);
})();