(function () {

	'use strict';

	var logger = angular.module('logEnhancer', []);

	// angular logging enhancements
	logger.provider('logEnhancer', function () {
		this.loggingPattern = '%s - %s: ';

		this.$get = function () {
			var loggingPattern = this.loggingPattern;
			return {
				enhanceAngularLog: function ($log) {
					$log.enabledContexts = [];

					$log.getInstance = function (context) {
						return {
							log: enhanceLogging($log.log, context, loggingPattern),
							info: enhanceLogging($log.info, context, loggingPattern),
							warn: enhanceLogging($log.warn, context, loggingPattern),
							debug: enhanceLogging($log.debug, context, loggingPattern),
							error: enhanceLogging($log.error, context, loggingPattern),
							enableLogging: function (enable) {
								$log.enabledContexts[context] = enable;
							}
						};
					};

					function enhanceLogging(loggingFunc, context, loggingPattern) {
						return function () {
							var contextEnabled = $log.enabledContexts[context];
							if (contextEnabled === undefined || contextEnabled) {
								var modifiedArguments = [].slice.call(arguments);
								modifiedArguments[0] = [sprintf(loggingPattern, moment().format("dddd h:mm:ss a"), context)] + modifiedArguments[0];
								loggingFunc.apply(null, modifiedArguments);
							}
						};
					}
				}
			};
		};
	});

	// module config
	logger.config(['logEnhancerProvider', function (logEnhancerProvider) {

		// settings
		logEnhancerProvider.loggingPattern = "%s::[%s]> ";

	}]);

	// module run function
	logger.run(['$log', 'logEnhancer', function ($log, logEnhancer) {

		// enhance ng log
		logEnhancer.enhanceAngularLog($log);

	}]);

})();