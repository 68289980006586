(function () {

	'use strict';

	angular.module('app.services').factory('userSession', userSession);

	userSession.$inject = ['$http', '$log', 'localStorageService'];

	function userSession($http, $log, localStorage) {

		var logger = $log.getInstance('userSession');

		var service = {
			create: create,
			destroy: destroy,
			save: save,
			clearSaved: clearSaved
		};

		return service;


		/////////


		function create(token, tokenType, tokenIssued, tokenExpires, userName) {

			/*jshint validthis: true */

			this.authorizationHeader = {
				'Authorization': tokenType + " " + token
			};

			this.token = token;
			this.tokenType = tokenType;
			this.tokenIssued = tokenIssued;
			this.tokenExpires = tokenExpires;
			this.userName = userName;

			// nastavi naj se vsi $http requesti izvajajo z dodanim Authorization headerjem
			$http.defaults.headers.common.Authorization = this.authorizationHeader.Authorization;
		}


		function destroy() {

			/*jshint validthis: true */

			this.token = null;
			this.tokenType = null;
			this.tokenIssued = null;
			this.tokenExpires = null;
			this.userName = null;

			// odstrani Authorization header iz $http requestov
			$http.defaults.headers.common.Authorization = null;
		}


		function save(token) {
			localStorage.set('token', token);
		}


		function clearSaved() {
			localStorage.remove('token');
		}
	}
})();