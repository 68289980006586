(function () {

	'use strict';

	angular.module('app').config(['$stateProvider', function ($stateProvider) {

		// set up states
		$stateProvider.state('layout.login', {
			url: "/login",
			controller: 'LoginController as vm',
			templateUrl: 'App_Js/app/login/login.html',
			data: {
				pageTitle: 'Prijava',
				layoutClass: 'login'
			}
		});

	}]);
})();