(function () {

	'use strict';

	angular.module('app').config(['$stateProvider', 'mediaProvider', function ($stateProvider, mediaProvider) {

		$stateProvider.state('layout', {
			abstract: true,
			url: '/{lang:(?:sl|en)}',
			templateUrl: mediaProvider.selectPartial('App_Js/app/layout/_layout'),
			controller: 'LayoutController as vm',
			reloadOnSearch: false
		});

		$stateProvider.state('layout.public', {
			abstract: true,
			controller: 'LayoutController as vm',
			templateUrl: mediaProvider.selectPartial('App_Js/app/layout/_public'),
			reloadOnSearch: false
		});

		$stateProvider.state('layout.profile', {
			abstract: true,
			templateUrl: 'App_Js/app/layout/_profile.widescreen.html',
			controller: 'ProfileLayoutController as vm',
			reloadOnSearch: false
		});

		$stateProvider.state('layout.404', {
			templateUrl: 'App_Js/app/errorCodes/404.html'
		});

	}]);

})();