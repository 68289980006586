(function () {

	'use strict';

	angular.module('app').controller('FooterController', FooterController);

	FooterController.$inject = ['$rootScope', '$scope', '$log', '$timeout', '$interval', '$state', '$translatePartialLoader', '$translate'];

	function FooterController($rootScope, $scope, $log, $timeout, $interval, $state, $translatePartialLoader, $translate) {

		$translatePartialLoader.addPart('footer');

		var logger = $log.getInstance($scope.controllerName);

		var vm = this;

		vm.activeLanguage = $translate.use();

		vm.changeLanguage = changeLanguage;

		/////////

		function init() {

		}

		function changeLanguage(lang) {

			$translate.use(lang);
			$state.go('layout.public.home', { lang: lang }, {});

		}

	}
})();