(function () {

	'use strict';

	angular.module('app').directive('ggFilters', ['$timeout', function ($timeout) {
		return {
			restrict: 'AE',
			replace: true,
			scope: {
			},
			bindToController: true,
			controller: 'ggFiltersController',
			controllerAs: 'vm',
			link: function (scope, element, attrs, ctrl) {
			},
			templateUrl: 'App_Js/app/filters/ggFilters.html'
		};
	}]);
})();