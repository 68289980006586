(function () {

	'use strict';

	angular.module('app').config(['$stateProvider', 'EDITOR_MODES', 'USER_ROLES', function ($stateProvider, EDITOR_MODES, USER_ROLES) {

		$stateProvider.state('layout.profile.routeseditor', {
			url: "/profile/editor/routes",
			templateUrl: "App_Js/app/profile/route-editor/route-editor.html",
			controller: 'RouteEditorCtrl as vm',
			data: {
				pageTitle: 'Urejanje tras'
			}
		});

	}]);
})();